// GENERAL
$project-name: 'wachumba';
$project-font-1: 'freight-sans-pro';

// $project-font-2: 'font-name2';
// $project-font-secondary: 'freight-sans-pro';


// COMPASS VARIABLES
$default-border-radius: 3px;
$default-transition-duration: .3s;
$default-transition-property: all;
$default-transition-function : ease;


// GRID VARIABLES
$grid-columns: 12;
$grid-gutter-width: 30px;
$screen-xs-phone: 370px;
$screen-phone: 576px;
$screen-tablet: 770px;
$screen-desktop: 992px;
$screen-lg-desktop: 1200px;
$screen-xl-desktop: 1440px;
$screen-xxl-desktop: 1600px;

$container-tablet:             (720px + $grid-gutter-width);
$container-sm:                 $container-tablet;
$container-desktop:            (940px + $grid-gutter-width);
$container-md:                 $container-desktop;
$container-large-desktop:      (1140px + $grid-gutter-width);
$container-lg:                 $container-large-desktop;


// FONT SETTINGS
$fs-6: 6px;
$fs-8: 8px;
$fs-10: 10px;
$fs-12: 12px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-18: 18px;
$fs-19: 19px;
$fs-20: 20px;
$fs-22: 22px;
$fs-24: 24px;
$fs-26: 26px;
$fs-28: 28px;
$fs-30: 30px;
$fs-32: 32px;
$fs-34: 34px;
$fs-36: 36px;
$fs-38: 38px;
$fs-40: 40px;
$fs-42: 42px;
$fs-46: 46px;
$fs-50: 50px;
$fs-54: 54px;
$fs-60: 60px;
$fs-64: 64px;
$fs-70: 70px;
$fs-72: 72px;
$fs-75: 75px;
$fs-78: 78px;
$fs-80: 80px;
$fs-88: 88px;
$fs-95: 95px;

$font-primary: $project-font-1, sans-serif;
// $font-primary-regular: $project-font-2, sans-serif;

$font-light: 300;
$font-regular: 400;
$font-bold: 700;
$font-black: 900;

$alfa: 48px;
$beta: 36px;
$gamma: 24px;
$delta: 18px;
$epsilon: 16px;
$zeta: 14px;


// COLORS SETTINGS
$color-black-normal: #262626;
$color-green-normal: #00aa82;
$color-yellow-normal: #fbdf06;
$color-blue-normal: #2270b8;
$color-blue-dark: #1e2c59;
$color-light-gray: #f4f4f4;
$color-orange-normal: #e95424;
$color-white-light: #e4e4e4;


// MEDIA QUERIES WIDTH
//Primary
// $xs: 543px;
// $sm: 766px;
// $md: 992px;
// $lg: 1220px;

// //Mobile
// $xs-small: 360px;
// $xs-normal: 460px;

// //Desktop
// $lg-small: 1100px;
// $lg-large: 1440px;
// $lg-large-xl: 1600px;

