.main-blog-group{
	@include bp(rev-small){
		background-color: $color-blue-dark;
		margin: 1em -25px 3em;
		width: auto;
		padding: 30px 20px 30px;
	}
}

.blog-headline-section{
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	background-image: url(../images/blog-test.svg);
	overflow: hidden;
	-webkit-mask-image: url(../images/blog-test.svg);
	-webkit-mask-size: cover;
	mask-image: url(../images/blog-test.svg);
	mask-size: cover;
	@include bp(rev-medium){
		padding-top: 20px;
	}
}

.blog-detail-headline-section{
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	background-image: url(../images/blog-test.svg);
	overflow: hidden;
	-webkit-mask-image: url(../images/blog-test.svg);
	-webkit-mask-size: cover;
	mask-image: url(../images/blog-test.svg);
	mask-size: cover;
	@include bp(rev-medium){
		padding-top: 40px;
	}
}

.blog-list{

}

.blog-list-more{
	.blog-item{
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.blog-content{
	display: block;
	&:hover{
		img{
			transform: scale(1.05, 1.05);
		}
		.gamma{
			color: $color-green-normal;
		}
	}
}

.blog-item{
	margin-bottom: 40px;
}

.blog-image-group{
	overflow: hidden;
	img{
		transition: transform 400ms ease-out;
	}
}

.blog-text-group{
	position: relative;
	z-index: 2;
	padding: 30px 10px 0;
	@include bp(medium) {
		padding: 30px 30px 0;
	}
	.gamma{
		margin-bottom: 0.5em;
	    height: 55px;
		@include font-size($fs-22);
	    @include bp(small) {
	    	height: 70px;
	        @include font-size($fs-28);
	    }
	    @include bp(medium) {
			height: 86px;
	        @include font-size($fs-36);
	    }
	}
	p{
		margin-bottom: 0;
		height: 60px;
	}
}

.blog-stats-list{
	display: inline-block;
	vertical-align: middle;
	padding: 12px 0;
}

.blog-stats-list-bg{
	padding: 12px 30px;
	position: absolute;
	top: -30px;
	// &:after{
	// 	content: '\e93d';
	// 	font-family: $project-name;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	z-index: -1;
	// 	@include font-size(46px);
	// 	color: $color-green-normal;
	// }
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left top;
	background-image: url( '../images/rectangle-skew-2.svg' );
	@include bp(medium) {
		margin-left: -15px;
	}
}

.blog-stats-list-bg-ext{
	top: -100px;
}

.blog-stats-list-item{
	display: inline-block;
	margin-right: 32px;
	&:last-child{
		margin-right: 0;
	}
	// display: flex;
	// align-items: center;
	// justify-content: space-between;
}

.blog-stats-list-icon{
	margin-right: 5px;
	color: #ffffff;
	@include font-size($fs-14);
}

.blog-stats-list-text{
	color: #ffffff;
	font-weight: 400;
	line-height: 1.2;
	@include font-size($fs-18);
}



.shape-blog-1{
	position: absolute;
	bottom: 70px;
	left: -90px;
	z-index: -1;
	transform: rotateZ(80deg);
	@include font-size(50px);
}

.shape-blog-2{
	position: absolute;
	bottom: 0;
	left: 430px;
	z-index: -1;
	transform: rotateZ(200deg);
	@include font-size(90px);
}

.shape-blog-3{
	position: absolute;
	top: -70px;
	right: 42%;
	z-index: 2;
	transform: rotateZ(40deg);
	@include font-size(180px);
	@include bp(rev-medium){
		top: -80px;
		right: -80px;
	}
}

.shape-blog-4{
	position: absolute;
	top: 20px;
	right: 50%;
	transform: rotateZ(20deg);
	z-index: 2;
	@include font-size(60px);
	@include bp(rev-medium){
		top: -40px;
		right: -30px;
	}
}

.shape-blog-5{
	position: absolute;
	bottom: 20px;
	right: 80px;
	transform: rotateZ(20deg);
	z-index: 2;
	@include font-size(80px);
	@include bp(rev-medium){
		right: 20px;
	}
	@include bp(rev-small){
		right: -60px;
	}
}

.shape-blog-6{
	position: absolute;
	left: -50px;
	top: 30px;
	transform: rotateZ(20deg);
	z-index: 2;
	@include font-size(90px);
}


.shape-blog-detail-1{
	position: absolute;
	top: 50px;
	left: 10px;
	z-index: -1;
	transform: rotateZ(40deg);
	@include font-size(70px);
}

.shape-blog-detail-2{
	position: absolute;
	bottom: 50px;
	left: 250px;
	z-index: -1;
	transform: rotateZ(165deg);
	@include font-size(70px);
}

.shape-blog-detail-3{
	position: absolute;
	top: -30px;
	left: 40%;
	z-index: 5;
	@include font-size(170px);
	&:before{
		color: $color-blue-normal;
	}
	@include bp(rev-medium){
		top: -100px;
		left: 60%;
	}
}

.shape-blog-detail-4{
	position: absolute;
	bottom: -30px;
	left: -50px;
	z-index: 5;
	transform: rotateZ(20deg);
	@include font-size(170px);
	@include bp(rev-medium){
		display: none;
	}
}

.shape-blog-detail-5{
	position: absolute;
	bottom: 30px;
	right: 150px;
	z-index: 5;
	@include font-size(100px);
	&:before{
		color: $color-blue-dark;
	}
	@include bp(rev-medium){
		bottom: -40px;
		right: 50px;
	}
}

.shape-blog-detail-6{
	position: absolute;
	top: 70px;
	right: -80px;
	z-index: 2;
	transform: rotateZ(130deg);
	@include font-size(250px);
	&:before{
		color: $color-green-normal;
	}
	@include bp(rev-medium){
		top: -80px;
		right: -150px;
	}
}

.shape-blog-detail-7{
	position: absolute;
	top: 260px;
	right: 40px;
	z-index: 3;
	transform: rotateZ(40deg);
	@include font-size(70px);
	@include bp(rev-medium){
		top: 100px;
		right: -30px;
	}
}