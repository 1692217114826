.testimonials-group {
	@include bp(rev-medium){
		margin-bottom: 140px;
		padding: 30px 15px 0;
	}
	@include bp(rev-small){
		margin-bottom: 80px;
	}
}

.testimonial-item {
	p{
		max-height: 260px;
		line-height: 24px;
		@include bp(rev-medium){
			max-height: 160px;
			padding: 0 5px;
		}
	}
}

.testimonials-author {
	display: block;
	color: #ffffff;
	line-height: 1.4;
	font-weight: 400;
	@include font-size(18px);
	strong{
		display: block;
		color: #ffffff;
		font-weight: 900;
		@include font-size(22px);
	}
}
