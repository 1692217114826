.order-group{
	// padding: 40px 0;
	padding: 20px 0;
	// &:nth-child(odd){
	@include bp(medium){
		&:hover{
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			background-color: #ba431d;
			.bar-color-1{
				background-color: #1a73b1;
			}

			.bar-color-2{
				background-color: #0faa86;
			}

			.bar-color-3{
				background-color: #203257;
			}

			.bar-color-4{
				background-color: #ee5133;
			}

			.bar-color-5{
				background-color: #5fc6ff;
			}

			.bar-color-6{
				background-color: #ff649d;
			}
			.order-bar-percent{
				color: #ffffff;
			}
			.btn{
				transform-origin: top center;
			    animation-name: swing;
			    animation-duration: 1s;
			    animation-fill-mode: both;
				border: 2px solid $color-yellow-normal;
		        background-color: $color-yellow-normal;
		        color: $color-black-normal;
			}
		}
	}
	@include bp(rev-medium){
		padding-bottom: 0;
		&.is-active{
			.js-dropdown-click-trigger{
			}
			.order-bg{
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				background-color: #ba431d;
			}
			.bar-color-1{
				background-color: #1a73b1;
			}

			.bar-color-2{
				background-color: #0faa86;
			}

			.bar-color-3{
				background-color: #203257;
			}

			.bar-color-4{
				background-color: #ee5133;
			}

			.bar-color-5{
				background-color: #5fc6ff;
			}

			.bar-color-6{
				background-color: #ff649d;
			}
			.order-bar-percent{
				color: #ffffff;
			}
			.btn{
				border: 2px solid $color-yellow-normal;
		        background-color: $color-yellow-normal;
		        color: $color-black-normal;
			}
		}
		.js-dropdown-click-content{
			.order-item{
				padding-bottom: 30px;
			}
		}
	}
	// &.order-group-main{
	// 	background-color: transparent;
	// 	box-shadow: none;
	// }
	@include bp(rev-xsmall){
		// padding: 5px 0;
	}
}

.order-group-main{
	@include bp(medium){
		padding-top: 0;
	}
	.order-item{
		padding-top: 10px;
	}
}

.order-item{
	padding-top: 20px;
	padding-bottom: 20px;
	@include bp(rev-medium){
		padding-right: 35px;
		padding-bottom: 10px;
	}
	.btn{
		@include bp(rev-large){
			padding-left: 5px;
			padding-right: 5px;
			line-height: 1.2;
		}
	}
}

.order-title{
	display: block;
	margin-bottom: 15px;
	color: #ffffff;
	font-weight: 900;
	line-height: 1.2;
	@include font-size($fs-22);
	@include bp(rev-medium){
		margin-bottom: 10px;
		margin-top: 15px;
		font-weight: 600;
		@include font-size($fs-20);
	}
}

.order-date{
	display: block;
	color: #ffffff;
	font-weight: 600;
	line-height: 1.2;
	@include font-size($fs-32);
	@include bp(rev-large){
		@include font-size($fs-24);
	}
	@include bp(rev-medium){
		display: inline-block;
		vertical-align: middle;
		float: left;
		margin-top: -5px;
		margin-bottom: 3px;
		width: 70%;
		font-weight: 600;
		text-align: left;
		@include font-size(20px);
	}
}

.order-text{
	display: block;
	margin-bottom: 5px;
	color: #ffffff;
	font-weight: 400;
	line-height: 1.2;
	@include font-size($fs-16);
	@include bp(rev-medium){
		margin-bottom: 10px;
	}
}

.order-text-big{
	@extend .order-text;
	margin-bottom: 0;
	@include font-size($fs-22);
	@include bp(rev-medium){
		@include font-size($fs-18);
	}
}

.order-bar{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.bar-color-1{
	background-color: #dae9f3;
}

.bar-color-2{
	background-color: #d9f1ec;
}

.bar-color-3{
	background-color: #dbdee4;
}

.bar-color-4{
	background-color: #fce3de;
}

.bar-color-5{
	background-color: #dae9f3;
}

.bar-color-6{
	background-color: #d9f1ec;
}

.order-bar-item{
	position: relative;
	height: 35px;
}

.order-bar-percent{
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	text-align: center;
	display: block;
	color: $color-black-normal;
	@include font-size($fs-14);
	font-weight: 900;
	@include bp(excl-large){
		@include font-size($fs-12);
	}
}

.order-bar-text{
	position: absolute;
	top: 120%;
	left: 0;
	width: 100%;
	text-align: center;
	@include font-size($fs-14);
	color: #ffffff;
}

.order-text-small{
	margin-bottom: 5px;
	color: #ffffff;
	font-weight: 400;
	line-height: 1.2;
	@include font-size($fs-16);
	@include bp(medium){
		display: none;
	}
}

.order-text-left{
	float: left;
	width: 70%;
	text-align: left;
}

.order-text-right{
	width: 30%;
	float: right;
	text-align: right;
}

.order-price{
	display: block;
	color: #ffffff;
	font-weight: 900;
	line-height: 0.8;
	@include font-size(48px);
	@include bp(rev-medium){
		width: 30%;
		float: right;
		margin-bottom: 3px;
		font-weight: 600;
		text-align: right;
		@include font-size(18px);
	}
}

.order-block{
	&.is-active{
		.order-text-hidden{
			display: none!important;
		}
		.order-text-open{
			display: block!important;
		}
	}
	.order-text-open{
		display: none!important;
	}
}

.order-trigger{
	display: block;
	position: absolute;
    top: -75px;
    cursor: pointer;
    left: 140px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	color: $color-black-normal;
	text-align: center;
	background-color: $color-yellow-normal;
	border-radius: 100%;
	z-index: 6;

	padding-top: 17px;
    line-height: 24px;
    width: 100px;
    height: 100px;
    top: -100px;
	
	@include font-size(30px);
	@include bp(rev-large){
		left: 0;
	}
	@include bp(rev-medium){
		width: 80px;
		height: 80px;
		line-height: 80px;
		line-height: 16px;
		top: -70px;
		left: 50%;
		margin-left: -40px;
		@include font-size(20px);
	}
	&.is-active{
		animation: none;
		&:after{
			content: "\e926";
		}
	}
}

.order-trigger-text{
	display: block;
	font-family: 'freight-sans-pro', sans-serif;
	@include font-size(20px);
	line-height: 1.2;
	font-weight: 500;
	@include bp(rev-medium){
		@include font-size(16px);
	}
}

.order-text-bold{
	display: block;
	color: #ffffff;
	margin-bottom: 3px;
	@include font-size(16px);
	font-weight: 900;
	font-weight: bold;
	text-transform: uppercase;
}

.order-text-link{
	display: block;
	color: $color-yellow-normal;
	padding: 3px 0;
	line-height: 1.2;
	text-decoration: underline;
	@include font-size(16px);
}