/* Icon helpers */

.icon-r {
    margin-right: 10px;
}

.icon-l {
    margin-left: 10px;
}

.icon-gray{
	color: rgba(0,0,0,0.3);
}

.icon-size-small{
	@include font-size(12px);
}

.icon-size-normal{
	@include font-size(14px);
}

.icon-size-big{
	@include font-size(16px);
}