/* Margin helpers */

.margin-0 {
    margin: 0 !important;    
}

.margin-xs-0 {
    margin: 0 !important;
}

.margin-sm-0 {
    @include bp(small) {
        margin: 0 !important;
    }
}

.margin-md-0 {
    @include bp(medium) {
        margin: 0 !important;
    }
}

.margin-lg-0 {
    @include bp(large) {
        margin: 0 !important;
    }
}

.margin-bottom-05{
    margin-bottom: 0.5em;
}

.margin-bottom-1{
    margin-bottom: 1em;
}

.margin-bottom-1-5{
    margin-bottom: 1.5em;
}

.margin-bottom-2{
    margin-bottom: 2em;
}

.margin-bottom-3{
    margin-bottom: 3em;
}

.margin-bottom-xs-1{
    @include bp(rev-xsmall){
        margin-bottom: 1em;
    }
}

.margin-bottom-xs-2{
    @include bp(rev-xsmall){
        margin-bottom: 2em;
    }
}

.margin-bottom-sm-1{
    @include bp(rev-small){
        margin-bottom: 1em;
    }
}


.margin-bottom-sm-2{
    @include bp(rev-small){
        margin-bottom: 2em;
    }
}

.margin-top-1{
    margin-top: 1em;
}

.margin-top-2{
    margin-top: 2em;
}

.margin-top-3{
    margin-top: 3em;
}

.h-no-margin{
    h1,h2,h3,h4,h5{
        margin: 0;
    }
}

.h-margin-02{
    h1,h2,h3,h4,h5{
        margin-bottom: 0.2em;
    }
}

.h-margin-05{
    h1,h2,h3,h4,h5{
        margin-bottom: 0.5em;
    }
}

.p-margin-0{
    p{
        margin-bottom: 0em;
    }
}


.p-margin-05{
    p{
        margin-bottom: 0.5em;
    }
}

.p-margin-1{
    p{
        margin-bottom: 1em;
    }
}



$ways: "top" "right" "bottom" "left";

@each $item in $ways {
    .margin-#{$item}-0 {
        margin-#{$item}: 0 !important;
    }
    
    .margin-#{$item}-xs-0 {
        @include bp(rev-xsmall) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-#{$item}-sm-0 {
        @include bp(rev-small) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-#{$item}-md-0 {
        @include bp(rev-medium) {
            margin-#{$item}: 0 !important;
        }
    }
    
    .margin-#{$item}-lg-0 {
        @include bp(rev-large) {
            margin-#{$item}: 0 !important;
        }
    }
}