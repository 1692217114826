
.team-content{
	cursor: pointer;
	&:hover{
		img{
			opacity: 0.2;
		}
		.team-description{
			opacity: 1;
		}
	}
}

.team-item{
	padding-top: 15px;
	padding-bottom: 15px;
}

.team-image-group{
	position: relative;
	// overflow: hidden;
	margin-bottom: 30px;
	background-color: $color-orange-normal;
}

.team-text-group{

}

.team-description{
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 0 30px;
	@include bp(rev-xlarge){
		padding: 0 15px;
	}
	p{
		z-index: 6;
		margin-bottom: 0;
		@include bp(rev-xlarge){
			@include font-size(18px);
		}
		@include bp(rev-small){
			line-height: 22px;
			@include font-size(14px);
		}
		@include bp(rev-xssmall){
			line-height: 20px;
		}
	}
}

.shape-team-1{
	position: absolute;
	top: 10px;
	left: 40px;
	z-index: 2;
	transform: rotateZ(-5deg);
	@include font-size(32px);
	@include bp(rev-small){
		top: 5px;
		@include font-size(20px);
	}
}

.shape-team-2{
	position: absolute;
	top: -15px;
	left: 160px;
	z-index: 1;
	@include font-size(62px);
	@include bp(rev-small){
		left: 100px;
		@include font-size(40px);
	}
	&:before{
		color: $color-blue-normal;
	}
}

.shape-team-3{
	position: absolute;
	bottom: -45px;
	left: -10px;
	z-index: 2;
	transform: rotateZ(190deg);
	@include font-size(120px);
	@include bp(rev-small){
		bottom: -20px;
		@include font-size(80px);
	}
}

.shape-team-4{
	position: absolute;
	bottom: 5px;
	left: 60px;
	z-index: 1;
	transform: rotateZ(50deg);
	@include font-size(40px);
	@include bp(rev-small){
		left: 40px;
		@include font-size(30px);
	}
}

.shape-team-5{
	position: absolute;
	bottom: 100px;
	right: -20px;
	z-index: 1;
	transform: rotateZ(40deg);
	@include font-size(45px);
	@include bp(rev-small){
		@include font-size(30px);
	}
}