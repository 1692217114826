/* Header */

$main-bg:        #1BBC9B;
$nav-color:      $color-orange-normal;
$nav-bg-active:  #ffffff;

.navigation{
    @include bp(rev-medium){
        > .container-large{
            width: 100%;
        }
        .nav-collapse{
            position: fixed;
            left: 0;
            top: 61px;
            z-index: 999;
            padding: 0 0 60px;
            overflow: auto;
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
            -o-transform: translateX(-100%);
            width: 100%;
            height: 100%;
            background-color: $nav-bg-active;
            @include transition(none 250ms ease);
        }

        .navigation-trigger {
            display: block;
            position: fixed;
            width: 70px;
            height: 60px;
            right: 0;
            top: 0;
            z-index: 200;
            transition: margin-top 400ms ease-out;
        }

        .navigation-trigger-icon {
            display: inline-block;
            position:  absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 35px;
            height: 3px;
            font-weight: 300;
            background-color:  $nav-color;
            transition-property:  background-color, transform;
            transition-duration:  250ms;
            -webkit-transition-property:  background-color, transform;
            -webkit-transition-duration:  250ms;
            &:before,
            &:after {
                content: '';
                display: block;
                width: 35px;
                height: 3px;
                position:  absolute;
                background:  $nav-color;
                transition-property:  margin, transform;
                transition-duration:  250ms;
                -webkit-transition-property:  margin, transform;
                -webkit-transition-duration:  250ms;
            }
            &:before {
                margin-top: -10px;
            }
            &:after {
                margin-top: 10px;
            }
        }
        &.is-collapse{
            border-bottom: 1px solid #eaeaea;
        }
        &.is-collapse .nav-collapse{
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            @include transition(transform 250ms ease);
        }
        &.is-collapse .navigation-trigger-icon {
            background:  rgba(0,0,0,0.0);
            &:before {
                margin-top: 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
            }
            &:after {
                margin-top: 0;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
            }
        }
    }
}


.header{

}

.navigation{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0;
    background-color: #ffffff;
    padding: 0 30px;
    transition: margin-top 400ms ease-out;
    // @include transition(box-shadow 350ms ease);
    
    // @include bp(medium){
    //     display: block;
    //     width: 100%;
    //     height: 140px;
    //     background-repeat: no-repeat;
    //     -webkit-background-size: cover;
    //     background-size: cover;
    //     background-position: center;
    //     // background-image: url(../images/header-shape.svg);
    //     overflow: hidden;
    //     -webkit-mask-image: url(../images/header-shape.svg);
    //     -webkit-mask-size: cover;
    //     mask-image: url(../images/header-shape.svg);
    //     mask-size: cover;
    // }
    
    @include bp(rev-medium){
        padding: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        // @include transition(box-shadow 350ms ease);
    }
}

.navigation-scroll{
    .header-logo{
        top: 10px;
    }
    .header-image{
        width: 65px;
    }
    .bg-image{
        display: none;
    }
    .dropdown-padding-ext{
        padding-top: 30px;
    }
    .menu-list-item-bg:hover:after,
    .menu-list-item-bg.is-active:after{
        height: 100px;
    }
    // .menu-list-link {
    //    padding: 15px 20px;
    //     @include bp(rev-large){
    //         padding: 15px 10px;
    //     }
    // }
    // .js-dropdown-classic .menu-list-item-ext .js-dropdown-content{
    //     top: 51px;
    // }
}

.navigation-scroll-bottom{
    margin-top: -65px;
    .navigation-trigger{
        margin-top: -65px;
    }
}


.header-logo{
    position: absolute;
    top: 20px;
    left: 0;
    padding: 0;
    float: left;
    text-align: center;
    transition: top 400ms ease-out;
    @include bp(rev-medium){
        padding: 5px 0 5px;
        height: 60px;
        z-index: 99;
        top: auto;
        position: relative;
    }
}

.header-logo-link{
    display: inline-block;
    vertical-align: middle;
}

.header-image{
    margin: 0 auto;
    transition: width 400ms ease-out;
    @include bp(rev-medium){
        width: 50px;
    }
}

// .nav-collapse{
// }

.nav-center{
    @include bp(medium){
        width: 100%;
        text-align: right;
        float: right;
    }
    @include bp(rev-medium){
        flex-wrap: wrap;
        display: flex;
        align-items: center;
    }
}

.menu-group{
    text-align: left;
    @include bp(medium){
        display: inline-block;
        vertical-align: middle;
    }
    @include bp(rev-medium){
        width: 100%;
    }
}

.menu-list{
    @include bp(rev-medium){
        display: flex;
        flex-wrap: wrap;
    }
}

.menu-list-item{
    position: relative;
    float: left;
    @include bp(medium){
        margin: 10px 5px;
    }
    @include bp(rev-medium){
        width: 100%;
        float: none;
        display: block;
        text-align: center;
    }
    &:hover,
    &.active{
        .menu-list-link{
        }
    }
}

.menu-list-item-ext{
    // position: inherit;
    .menu-list-link{
        color: #ffffff!important;
        font-weight: 600;
        z-index: 1;
        padding: 20px 15px;
        @include bp(rev-medium){
           @include font-size(20px);
            padding: 20px 10px;
        }
    }
    @include bp(medium){
        &:after{
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            display: block;
            width: 100%;
            z-index: 0;
            opacity: 0;
            height: 0;
            -webkit-transition: height 200ms ease-out;
            -o-transition: height 200ms ease-out;
            transition: height 200ms  ease-out;
        }
        &:hover,
        &.is-active{
            &:after{
                opacity: 1;
                height: 140px;
            }
            &.bg-orange{
                &:after{
                    background-color: $color-orange-normal;
                }
            }
            &.bg-green{
                &:after{
                    background-color: $color-green-normal;
                }
            }
        }
    }
}


.menu-list-item-bg{
    .menu-list-link{
        z-index: 1;
    }
    @include bp(medium){
        &:after{
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            display: block;
            width: 100%;
            z-index: 0;
            opacity: 0;
            height: 0;
            -webkit-transition: height 200ms ease-out;
            -o-transition: height 200ms ease-out;
            transition: height 200ms  ease-out;
            background-color: $color-orange-normal;
        }
        &:hover,
        &.is-active{
            &:after{
                height: 152px;
                opacity: 1;
            }
        }
    }
}

.js-dropdown-classic .menu-list-item-bg .js-dropdown-content{
    top: 71px;
}

.menu-list-item-top{
    margin-top: 15px;
}

.menu-list-link{
    display: block;
    color: #000000;
    font-weight: 400;
    line-height: 1.2;
    padding: 12px 10px;
    @include font-size(18px);
    transition: padding-top 400ms ease-out, padding-bottom 400ms ease-out;
    &:hover{
        color: $color-orange-normal;
    }
    @include bp(medium){
        text-transform: uppercase;
        padding: 20px 15px;
        @include font-size(18px);
    }
    @include bp(large){
        padding: 20px 15px;
    }
}

.header-info-group{
    display: flex;
    align-items: center;
    @include bp(medium){
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
    }
    @include bp(large){
        margin-left: 30px;
    }
    @include bp(rev-medium){
        width: 100%;
        justify-content: center;
        padding: 15px 0;
    }
}

.header-icon{
    float: left;
    // display: inline-block;
    // vertical-align: middle;
    margin-right: 5px;
    position: relative;
    top: 3px;
    @include font-size(44px);
    color: $color-yellow-normal;
}

.header-text-group{
    display: block;
    height: 100%;
    overflow: hidden;
    color: #000000;
    font-family: Roboto;
    @include font-size($fs-18);
    font-weight: 600;
}

.header-info-link{
    display: block;
    line-height: 1.2;
    padding: 2px 0;
    @include bp(rev-medium){
        padding: 5px 0;
        text-align: center;
    }
    &:hover{
        text-decoration: underline;
    }
}

.dropdown-padding{
    display: block;
    padding: 70px 0 0;
    @include bp(rev-medium){
        padding: 30px 5px 20px;
    }
}

.dropdown-padding-ext{
    padding: 70px 0 20px;
    @include bp(rev-medium){
        padding: 30px 0 20px;
    }
}

.submenu-list{
    text-align: left;
}

.submenu-item{
    position: relative;
    text-align: left;
    margin-bottom: 30px;
    .submenu-list-link{
        padding-left: 30px;
        @include bp(rev-medium){
            padding-left: 35px;
        }
    }
}

.submenu-icon{
    position: absolute;
    top: 0;
    left: -40px;
    @include font-size($fs-60);
    @include bp(rev-medium){
        left: -15px;
        @include font-size($fs-40);
    }
}

.submenu-list-item{
    display: block;
    margin-bottom: 2px;
    @media (min-width: 992px) and (max-height: 860px){
        margin-bottom: 0;
    }
}

.submenu-list-link{
    display: inline-block;
    padding: 3px 18px;
    color: #ffffff;
    font-weight: 400;
    line-height: 1.4;
    @include font-size($fs-16);
    @include bp(small){
        @include font-size($fs-18);
    }
    @include bp(medium){
        @include font-size($fs-18);
    }
    &:hover{
        text-decoration: underline;
    }
    @media (min-width: 992px) and (max-height: 860px){
        @include font-size($fs-16);
    }
}

.submenu-title{
    display: block;
    padding: 0 18px;
    padding-left: 35px;
    margin-bottom: 15px;
    color: #ffffff;
    font-weight: 900;
    line-height: 1.2;
    text-transform: uppercase;
    @include font-size($fs-16);
    @include bp(xsmall){
        @include font-size($fs-18);
    }
    @include bp(medium){
        padding-left: 30px;
        @include font-size($fs-22);
    }
    @include bp(large){
        @include font-size($fs-26);
    }
}