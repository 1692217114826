.main-offer-group{

}

.main-offer-item{
	.gamma{
		@include bp(excl-small){
			@include font-size(24px);
		}
		@include bp(excl-medium){
			@include font-size(28px);
		}
	}
}

.main-offer-icon{
	@include font-size(110px);
}


.offer-group{
    position: relative;
    text-align: center;
    margin: 6em 0 2em;
    padding: 2em 0;
    @include bp(rev-small){
    	margin: 3em 0 2em;
    }
    &:after{
		content: '';
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    display: block;
	    width: 961px;
	    height: 146px;
	    transform: translate(-50%, -50%);
	    z-index: -1;
	    background-size: cover;
	    background-position: top;
	    background-repeat: no-repeat;
	    background-image: url( '../images/rectangle-blog.svg' );
	    @include bp(rev-medium){
	    	width: 100%;
	    	height: 100%;
	    }
    }
	.offer-text{
		display: inline-block;
		vertical-align: middle;
		font-weight: 900;
		margin: 10px 15px;
		line-height: 1.4;
		@include font-size($fs-26);
		@include bp(rev-small){
			@include font-size($fs-22);
		}
	}
	.offer-btn{
		display: inline-block;
		vertical-align: middle;
		margin: 10px 15px;
		&:hover{
			color: $color-black-normal;
		}
	}
}

.offer-group-bottom{
	@include bp(rev-medium){
		margin: 0;
		padding: 0;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-bottom: -210px;
		width: 95%;
		transform: translateX(-50%);
		&:after{
			display: none;
		}
		.offer-content{
			margin: 0;
			padding: 2em 1em;
			&:after{
				content: '';
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    display: block;
			    width: 961px;
			    height: 146px;
			    transform: translate(-50%, -50%);
			    z-index: -1;
			    background-size: cover;
			    background-position: top;
			    background-repeat: no-repeat;
			    background-image: url( '../images/rectangle-blog.svg' );
			    @include bp(rev-medium){
			    	width: 100%;
			    	height: 100%;
			    }
		    }
		}
	}
	@include bp(medium){
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-bottom: -200px;
		transform: translateX(-50%);
		&:after{
			display: none;
		}
		.offer-content{
			position: relative;
			width: 960px;
			margin: 0 auto;
			padding: 2em 0;
			&:after{
				content: '';
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    display: block;
			    width: 961px;
			    height: 146px;
			    transform: translate(-50%, -50%);
			    z-index: -1;
			    background-size: cover;
			    background-position: top;
			    background-repeat: no-repeat;
			    background-image: url( '../images/rectangle-blog.svg' );
			    @include bp(rev-medium){
			    	width: 100%;
			    	height: 100%;
			    }
		    }
		}
	}
	@include bp(excl-xlarge){
		margin-bottom: -180px;
	}
	@include bp(excl-large){
		margin-bottom: -180px;
	}
}

.offer-group-detail{
	margin: 4em 0 5em;
	@include bp(rev-medium){
		margin: 1em 0 3em;
	}
}

.shape-offer-1{
	position: absolute;
	display: block;
	top: -60px;
	left: -75px;
	@include font-size(150px);
	z-index: 2;
	transform: rotateZ(160deg);
	@include bp(rev-medium){
		left: -25px;
	}
}

.shape-offer-2{
	position: absolute;
	display: block;
	top: -25px;
	left: -15px;
	@include font-size(65px);
	transform: rotateZ(110deg);
	@include bp(rev-medium){
		top: -25px;
		left: 45px;
		@include font-size(45px);
	}
}

.shape-offer-3{
	position: absolute;
	display: block;
	bottom: -20px;
	right: -55px;
	@include font-size(40px);
	transform: rotateZ(-3deg);
	@include bp(rev-large){
		right: -20px;
	}
	@include bp(rev-medium){
		right: -10px;
	}
}