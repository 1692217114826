/* Form helpers */

%input {
    @include Font1;
    @include font-size(20px);
    @include border-radius(0);
    padding: 5px 20px;
    height: 50px;
    color: $color-black-normal;
    width: 100%;
    font-weight: 400;
    // border: 1px solid $color-black-normal;
    border: 0;
    -webkit-appearance: none;
    outline: none!important;
    &:focus {
        //border-color: $color-black-normal;
    }
}

.form-input {
    @extend %input;
    background-color: $color-light-gray;
}

.form-input-ext{
    color: $color-black-normal;
    background-color: #ffffff;
    // border: 0;
}


.form-textarea {
    @extend %input;
    background-color: $color-light-gray;
    height: 100px;
    line-height: 1.5em;
    resize: none;
}

.form-select{
    width: 100%;
    padding: 5px 20px;
    height: 50px;
    border: 0;
    text-align: center;
    background-color: $color-light-gray;
    color: $color-black-normal;
    @include font-size(20px);
    font-weight: 400;
    line-height: 1.2;
    outline: none;
    -webkit-appearance: value;
    -moz-appearance: value;
    -ms-appearance: value;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

.form-captcha{
    transform: scale(0.77);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}


.form-label {
    display: block;
    margin-bottom: 8px;
    color: $color-black-normal;
    @include font-size(14px);
}


.form-line {
    margin-bottom: 30px;
}

.form-line-submit{
    margin-top: 40px;
}

.form-placeholder{
    &.active{
        .form-label {
            -webkit-transform: translateX(20px) translateY(8px);
            transform: translateX(20px) translateY(8px);
            width: auto;
            font-size: 10px;
        }
    }
    .form-label {
        position: absolute;
        -moz-transform: translateX(20px) translateY(15px);
        -ms-transform: translateX(20px) translateY(15px);
        -o-transform: translateX(20px) translateY(15px);
        -webkit-transform: translateX(20px) translateY(15px);
        transform: translateX(20px) translateY(15px);
        cursor: text;
        top: 0;
        left: 0;
        transition: all .3s ease;
        padding: 1px;
        margin: 0;
        z-index: 9;
        text-transform: none;
    }
}
