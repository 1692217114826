/* Buttons helper */

.btn {
    @include transition(background-color 250ms ease);
    @include border-radius(0);
    @include font-size($fs-14);
    display: inline-block;
    margin: 0;
    padding: 18px 15px;
    text-align: center;
    border: 0;
    background: $color-black-normal;
    text-decoration: none!important;
    text-transform: uppercase;
    font-weight: $font-black;
    -webkit-appearance: none;
    line-height: 1em;
    transition: box-shadow 250ms ease;
    outline: none!important;
    &, &:visited, &:active, &:focus {
        color: white;
    }
    
    &:hover {
        @extend .swing;
        transition: box-shadow 250ms ease;
        @include bp(medium) {
            text-decoration: none;
            cursor: pointer;
            color: white;
        }
    }
}

.btn-responsive {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    text-align: center;
}

.btn-xs-responsive{
    @include bp(rev-xsmall){
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        min-width: auto;
        width: 100%;
    }
}

.btn-absolute{
    // animation: none!important;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    padding: 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    @include font-size($fs-16);
    // &:hover{
    //     background-color: darken($color-yellow-normal,10%)!important;
    // }
    .btn-icon{
        position: relative;
        top: 2px;
        color: $color-black-normal;
    }
}

.btn-icon{

}

.btn-small{
    padding: 10px 20px;
}

.btn-normal{
    padding: 16px 20px;
}

.btn-big{
    padding: 20px 20px;
}

.btn-min-width-normal{
    min-width: 150px;
}

.btn-min-width-big{
    min-width: 180px;
}


.button-group{
    display: inline-block;
    text-align: center;
    .button-item{
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 15px;
    }
}


.btn-black-fill{
    background-color: #000000;
    color: #ffffff;
    &, &:visited, &:active, &:focus {
        color: #ffffff;
    }
    &:hover{
        border: 1px solid #000000;
        background-color: #ffffff;
        color: #000000;
        .btn-icon{
            color: #000000;
        }
    }
    .btn-icon{
        color: #ffffff;
    }
}

.btn-yellow-fill{
    background-color: $color-yellow-normal;
    color: $color-black-normal;
    &, &:visited, &:active, &:focus {
        color: #000000;
    }
    &:hover{
        background-color: darken($color-yellow-normal,0);
        color: $color-black-normal;
    }
    .btn-icon{
        color: $color-black-normal;
    }
}

.btn-facebook-fill{
    background-color: #3b5797;
    color: #ffffff;
    &, &:visited, &:active, &:focus {
        color: #ffffff;
    }
    &:hover{
        background-color: darken(#3b5797,0);
        color: #ffffff;
    }
    .btn-icon{
        color: #ffffff;
    }
    .share-icon{
        color: #ffffff;
        width: 20px;
        margin-right: 8px;
        @include font-size($fs-18);
    }
    .share-btn-text{
        position: relative;
        top: -2px;
        text-transform: uppercase;
        font-weight: 900;
        color: #ffffff;
    }
}

.btn-black-outline{
    border: 1px solid #000000;
    color: #000000;
    &, &:visited, &:active, &:focus {
        color: #000000;
    }
    &:hover{
        border: 1px solid #000000;
        background-color: #000000;
        color: #ffffff;
        .btn-icon{
            color: #ffffff;
        }
    }
    .btn-icon{
        color: #000000;
    }
}

.btn-yellow-outline{
    background-color: transparent;
    border: 2px solid $color-yellow-normal;
    color: #000000;
    &, &:visited, &:active, &:focus {
        color: #000000;
    }
    &:hover{
        border: 2px solid $color-yellow-normal;
        background-color: $color-yellow-normal;
        color: #000000;
    }
}

.btn-yellow-outline-ext{
    background-color: transparent;
    border: 2px solid $color-yellow-normal;
    color: $color-yellow-normal;
    &, &:visited, &:active, &:focus {
        color: $color-yellow-normal;
    }
    &:hover{
        border: 2px solid $color-yellow-normal;
        background-color: $color-yellow-normal;
        color: $color-black-normal;
    }
}


.btn-yellow-outline-white{
    background-color: transparent;
    border: 2px solid $color-yellow-normal;
    color: #ffffff;
    &, &:visited, &:active, &:focus {
        color: #ffffff
    }
    &:hover{
        border: 2px solid $color-yellow-normal;
        background-color: $color-yellow-normal;
        color: #ffffff;
    }
}
