/* Color helpers */

.bg-black {
    background-color: #000000;
}

.bg-orange {
    background-color: $color-orange-normal;
}

.bg-green {
    background-color: $color-green-normal;
}

.bg-gray-light{
    background-color: $color-light-gray;
}

.txt-white{
    color: #ffffff;
}

.txt-black{
    color: #000000;
}

.txt-yellow-icon{
	&:before{
		color: $color-yellow-normal;
	}
}

.h-white{
	h1, h2, h3, h4, h5, h6{
		color: #ffffff;
	}
}

.h-orange{
	h1, h2, h3, h4, h5, h6{
		color: $color-orange-normal;
	}
}

.p-white{
	p{
		color: #ffffff;
	}
	.a-hover{
	    .a-hover-icon{
	        color: #ffffff;
	    }
	    .a-hover-text{
	        color: #ffffff;
	    }
	}
}