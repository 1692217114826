/* Typography */

.typography{
	p, 
    li, 
    td, 
    th {
        line-height: 1.5em;
    }

    p{
        &:last-child{
            margin-bottom: 0;
        }
    }

    p,
    ul,ol,
    h1, h2, h3, h4, h5, h6,
    table,
    fieldset,
    blockquote,
    iframe {
        margin-bottom: 1em;
    }

	h1{
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 1.2;
		font-weight: 600;
		@include font-size($fs-34)
		@include bp(xsmall){
			@include font-size($fs-38)
		}
		@include bp(small){
			@include font-size($fs-46)
		}
	}

	h2{
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 1.2;
		font-weight: 600;
		@include font-size($fs-28)
		@include bp(xsmall){
			@include font-size($fs-32)
		}
		@include bp(small){
			@include font-size($fs-36)
		}
	}

	h3{
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 1.2;
		font-weight: 600;
		@include font-size($fs-26)
		@include bp(xsmall){
			@include font-size($fs-28)
		}
		@include bp(small){
			@include font-size($fs-32)
		}
	}

	h4{
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		line-height: 1.2;
		font-weight: 600;
		@include font-size($fs-24)
		@include bp(xsmall){
			@include font-size($fs-26)
		}
		@include bp(small){
			@include font-size($fs-28)
		}
	}

	a{
		text-decoration: underline;
		&:hover{
			color: $color-orange-normal;
			text-decoration: underline;
		}
	}

	p{
		color: $color-black-normal;
		line-height: 38px;
		font-weight: 400;
		margin-bottom: 30px;
		@include font-size($fs-20);
		@include bp(rev-small){
			line-height: 32px;
			@include font-size($fs-18);
		}
	}

	strong{
		font-weight: 700;
	}
	
	img{
		width: 100%;
		margin-bottom: 50px;
	}

	ul,
	ol{
		padding-left: 0;
		margin-bottom: 40px;
		list-style: none;
		li{
			position: relative;
			color: $color-black-normal;
			font-weight: 400;
			margin-bottom: 30px;
			padding-left: 25px;
			line-height: 1.4;
			margin-bottom: 10px;
			@include font-size($fs-20);
			@include bp(rev-small){
				@include font-size($fs-18);
			}
		}
	}

	ul{
		li{
			&:before{
				content: '\e923';
				font-family: $project-name;
				line-height: 1.4;
				position: absolute;
				left: 0;
				top: 5px;
				text-align: center;
				@include font-size($fs-14);
				@include bp(rev-small){
					@include font-size($fs-12);
				}
			}
		}
	}

	ol{
		counter-reset: item;
		list-style-position:inside;
		li{
			&:before{
				display: inline-block;
				position: absolute;
				top: 3px;
				left: 0;
				font-family: $font-primary;
				content: counter(item);
				counter-increment: item;
				line-height: 1.4;
				@include font-size($fs-16);
				@include bp(rev-small){
					@include font-size($fs-14);
				}
			}
		}
	}
}

.share-group{
	text-align: center;
	margin: 3em 0;
	@include bp(rev-small){
    	margin: 2em 0;
    }
	.share-text{
		display: inline-block;
		vertical-align: middle;
		font-weight: 900;
		margin: 10px 15px;
		line-height: 1.4;
	}
	.share-btn{
		display: inline-block;
		vertical-align: middle;
		margin: 10px 15px;
	}
}
