.select-text{
    display: block;
    position: relative;
    color: $color-black-normal;
    font-weight: 300;
    font-size: 16px;
    border-radius: 10px 10px 0 0;
    min-width: 200px;
    text-align: center;
    height: 50px;
    padding: 0 40px 0 30px;
}

.select-product-text-block{
    display: flex;
    align-items: center;
    float: left;
    width: 60%;
    @include font-size($fs-19);
    font-weight: 200;
}

.select-product-info-block{
    float: right;
    width: 40%;
    text-align: right;
}

.select-product-name{
    line-height: 1.1;
}


.select-product-small{
    display: inline-block;
    vertical-align: middle;
    padding: 0 15px;
    color: $color-black-normal;
    line-height: 1.2;
    @include font-size($fs-20);
    // @include font-secondary();
}


.select-text-ext{
    @extend .select-text;
    display: block;
    background-color: $color-light-gray;
    text-align: left;
    width: 100%;
    border-radius: 0;
    color: $color-black-normal;
    padding: 0 20px 0 20px;
    overflow: hidden;
    .js-select-dropdown-result{
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: space-between
    }
}

.select-list-ext{
    .select-list-link{
        padding: 10px 20px 10px 20px;
        overflow: hidden;
        &:hover{
            background-color: #d5d4d4;
        }
    }
}


.select-list-item{
    display: block;
    font-size: 18px;
    font-weight: 400;
}

.select-list-link{
    display: flex;
    align-items: center;
    padding: 10px 40px;
    line-height: 1.2;
    cursor: pointer;
    &:hover{
        color: $color-black-normal;
    }
}