/* Dropdown block */

.js-dropdown-block{
    @media (min-width: 1025px){
        &:hover{
            .js-dropdown-trigger{
                &:after{
                    content: '\e926';
                }
            }
            .js-dropdown-content{
                height: auto;
                width: 100%;
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &.is-active{
        .js-dropdown-trigger{
            &:after{
                content: '\e926';
            }
        }
        .js-dropdown-content{
            height: auto;
            width: 100%;
            visibility: visible;
            opacity: 1;
            @include bp(rev-medium){
                position: fixed;
                top: 100px;
                overflow: auto;
                height: calc(100% - 100px);
            }
        }
    }
    .js-dropdown-trigger{
        position: relative;
        cursor: pointer;
        &:after{
            content: '\e925';
            font-family: $project-name;
            position: absolute;
            top: 50%;
            right: 20px;
            color: #ffffff;
            @include font-size($fs-12);
        }
    }
    .js-dropdown-content{
        display: block;
        position: absolute;
        top: 101%;
        left: 0;
        height: 0;
        width: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        background-color: rgba(255,255,255,1);
        transition: opacity 400ms ease-out;
    }
}

.js-dropdown-classic{
    .js-dropdown-block{
        @media (min-width: 1025px){
            &:hover .js-dropdown-trigger{
                color: #ffffff;
                background-color: $color-orange-normal;
                transition: opacity 400ms ease-out;
            }
            &.bg-green:hover .js-dropdown-trigger{
                background-color: $color-green-normal;
            }
        }
        &.is-active{
            .js-dropdown-trigger{
                background-color: $color-orange-normal;
                color: #ffffff;
                transition: opacity 400ms ease-out;
            }
            &.bg-green .js-dropdown-trigger{
                background-color: $color-green-normal;
            }
            .js-dropdown-content{
                @include bp(rev-medium){
                    position: relative;
                    top: auto;
                    overflow: hidden;
                    height: auto;
                }
            }
        }
    }
    .menu-list-item-ext {
        .js-dropdown-content{
            position: fixed;
            top: 81px;
            @include bp(rev-medium){
                position: relative;
                top: auto;
            }
        }
        .dropdown-padding{
            @include bp(medium){
                padding-top: 100px;
            }
        }
    }
    .js-dropdown-trigger{
        position: relative;
        cursor: pointer;
        &:after{
            @include bp(medium){
                display: none;
            }
            @include bp(rev-medium){
                right: 27px;
                @include font-size(16px)
                margin-top: -9px;
            }
        }
    }
    .js-dropdown-content{
        @include bp(medium){
            z-index: -1;
            min-width: 180px;
            overflow: visible;
        }
    }
    .bg-orange{
        background-color: $color-orange-normal;
    }
    .bg-green{
        background-color: $color-green-normal;
    }
}

.js-dropdown-click-block{
    &.is-active{
        .js-dropdown-click-content{
            height: auto;
            width: 400px;
            visibility: visible;
            opacity: 1;
            overflow: visible;
            text-align: left;
            padding-top: 25px;
            margin-right: -65px;
        }
    }
    .js-dropdown-click-trigger{
        cursor: pointer;
    }
    .js-dropdown-click-content{
        position: absolute;
        right: 0;
        top: 100%;
        height: 0;
        width: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        border-top: 0;
    }
}

.js-dropdown-click-block-ext{
    @include bp(rev-medium){
        &.is-active{
            .js-dropdown-click-content{
                height: auto;
                visibility: visible;
                opacity: 1;
                width: auto;
                overflow: visible;
            }
            .js-dropdown-click-trigger{
                &:after{
                    content: '\e925';
                }
            }
        }
        .js-dropdown-click-trigger{
            cursor: pointer;
            position: relative;
            &:after{
                content: '\e923';
                font-family: $project-name;
                position: absolute;
                top: 10px;
                right: 10px;
                // margin-top: -9px;
                color: #ffffff;
                @include font-size($fs-16);
            }
        }
        .js-dropdown-click-content{
            height: 0;
            width: 0;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
        }
    }
}


.js-dropdown-block-ext-1{
    @extend .js-dropdown-click-block;
    display: block;
    position: relative;
    // z-index: 9;
    &.is-active{
        .js-dropdown-click-content{
            width: 100%;
            padding-top: 0;
            margin-right: 0;
            background: #e8e8e8;
            max-height: 220px;
            overflow: auto;
        }
        .js-dropdown-click-trigger{
            &:after{
                content: '\e926';
            }
        }
    }
    .js-dropdown-click-trigger{
        &:after{
            content: '\e925';
            font-family: $project-name;
            position: absolute;
            top: 50%;
            right: 10px;
            font-weight: 300;
            @include font-size($fs-14);
            @include transform(translateY(-50%));
        }
    }
    .js-dropdown-click-content{
        background-color: transparent;
    }
}
