.benefits-group{

}

.benefits-item{
	margin-bottom: 50px;
	&:last-child{
		margin-bottom: 0;		
	}
}

.benefits-number-block{
	position: relative;
	float: left;
	width: 70px;
	margin-right: 25px;
	text-align: center;
	@include bp(rev-large){
		width: 60px;
		margin-right: 15px;
	}
	@include bp(rev-xsmall){
		width: 40px;
		margin-right: 15px;
	}
	.icon{
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		transform: translate(-50%, -50%);
		@include font-size(60px);
	}
	.benefits-icon-1{
		@include font-size(80px);
		transform: translate(-65%,-65%) rotateZ(140deg);
	}

	.benefits-icon-2{
		transform: translate(-45%, -55%) rotateZ(130deg);
	}

	.benefits-icon-3{
		@include font-size(40px);
		transform: translate(-30%, -85%) rotateZ(110deg);
	}

	.benefits-icon-4{
		@include font-size(50px);
		transform: translate(-120%, -65%) rotateZ(-30deg);
	}

	.benefits-icon-5{
		transform: translate(-90%, -41%) rotateZ(50deg);
	}

	.benefits-icon-6{
		@include font-size(50px);
		transform: translate(-90%, -105%) rotateZ(0);
	}
}

.benefits-number{
	display: block;
	position: relative;
	top: -20px;
	color: $color-black-normal;
	font-weight: 900;
	line-height: 0.6;
	@include font-size(84px);
	@include bp(rev-large){
		top: -10px;
		@include font-size(62px);
	}
	@include bp(rev-xsmall){
		@include font-size(42px);
	}
}

.benefits-text-block{
	display: block;
	height: 100%;
	overflow: hidden;
}
