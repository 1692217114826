.basket-side-panel{
	@include bp(medium){
		&.is-active .side-panel-content{
			position: fixed;
		    top: 90px;
		    bottom: auto;
		}
		&.is-complete .side-panel-content{
			bottom: 180px!important;
			top: auto!important;
			position: absolute!important;
		}
	}
}

.side-panel-content{
	@include bp(medium){
		width: 270px;
	}
	@include bp(large){
		width: 340px;
	}
	@include bp(xlarge){
		width: 400px;
	}
}


.side-panel{

}

.side-panel-trigger{
	position: relative;
	@include bp(rev-medium){
		cursor: pointer;
		border: 2px solid #ffffff;
		padding: 12px 20px;
		background-color: #ffffff;
		.gamma{
			@include font-size(22px);
			margin-bottom: 0;
			color: $color-orange-normal;
		}
		 &:after{
	        content: '\e925';
	        font-family: $project-name;
	        position: absolute;
	        right: 20px;
	        top: 50%;
	        display: block;
	        line-height: 1;
	        @include font-size($fs-22);
	        color: $color-orange-normal;
	        @include transform(translateY(-50%));
	    }
	}
	@include bp(rev-small){
		.gamma{
			@include font-size(22px);
		}
	}
}

.category-side-panel{
    // -ms-flex: 0 0 100%;
    // flex: 0 0 100%;
    // max-width: 100%;
	@include bp(medium){
		position: inherit;
	}
	// @include bp(medium){
	// 	width: 240px;
	// 	flex: 0 0 240px;
	// 	max-width: 240px;
	// }
	// @include bp(large){
	// 	width: 320px;
	// 	flex: 0 0 320px;
	// 	max-width: 320px;
	// }
	@media (max-width: 1440px) and (min-width: 991px){
		.shape-side-circle{
			display: none!important;
		}
	}
	&.bg-green{
		&:before{
			@include bp(medium){
				background-color: $color-green-normal;
			}
		}
		.side-panel-trigger{
			@include bp(rev-medium){
				.gamma{
					color: $color-green-normal;
				}
				 &:after{
			        color: $color-green-normal;
			    }
			}
		}
	}
	&.bg-orange{
		&:before{
			@include bp(medium){
				background-color: $color-orange-normal;
			}
		}
		.side-panel-trigger{
			@include bp(rev-medium){
				.gamma{
					color: $color-orange-normal;
				}
				 &:after{
			        color: $color-orange-normal;
			    }
			}
		}
	}
	&:before{
		@include bp(medium){
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			height: 100%;
			width: 100%;
			z-index: -1;
			width: 25%;
		}
	}
}

.basket-side-panel{
	@include bp(medium){
		padding-left: 25px;
		padding-right: 25px;
		position: inherit;
	}
	&:before{
		@include bp(medium){
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			height: 100%;
			background-color: $color-light-gray;
			width: 100%;
			z-index: -1;
			width: 30%;
		}
	}
}


.category-list{
	@include bp(rev-medium){
		margin-top: 10px;
	}
}

.category-list-item{

}

.category-list-link{
	@include font-size($fs-22);
	color: #ffffff;
	font-weight: 700;
	line-height: 1.2;
	@include bp(small){
		@include font-size($fs-24);
	}
	@include bp(medium){
		@include font-size($fs-26);
	}
	&:hover{
		text-decoration: underline;
	}
}

.category-sublist{
	@include bp(rev-medium){
		column-count: 3
	}
	@include bp(rev-small){
		column-count: 2;
	}
	@include bp(rev-xssmall){
		column-count: 1;
	}
}

.category-sublist-item{
	display: block;
	margin-bottom: 4px;
}

.category-sublist-link{
	color: #ffffff;
	padding: 5px 5px 5px 0;
	display: block;
	line-height: 1.2;
	font-weight: 400;
	@include font-size($fs-18);
	@include bp(medium){
		@include font-size($fs-20);
	}
}