/* Position helpers */

.pos-r {
    position: relative;
}

.pos-f{
	position: fixed;
}

.pos-s{
	position: static;
}

.pos-a{
	position: absolute;
}

.pos-auto{
	position: inherit;
}

.pos-r-50{
	@include bp(medium){
		position: absolute;
		top: 0;
		right: 0;
		width: 50%;
		max-width: 50%;
		flex: 0 0 50%;
		height: 100%;
		display: block;
	}
	@include bp(rev-small){
		.pos-content{
			margin: 0 -15px;
		}
	}
}

.pos-r-60{
	@include bp(medium){
		position: absolute;
		top: 0;
		right: 0;
		width: 60%;
		max-width: 60%;
		flex: 0 0 60%;
		height: 100%;
		display: block;
	}
	@include bp(rev-small){
		.pos-content{
			margin: 0 -15px;
		}
	}
}

.pos-r-66{
	@include bp(medium){
		position: absolute;
		top: 0;
		right: 0;
		width: 66.666667%;
		max-width: 66.666667%;
		flex: 0 0 66.666667%;
		height: 100%;
		display: block;
	}
	@include bp(rev-small){
		.pos-content{
			margin: 0 -15px;
		}
	}
}


.pos-l-50{
	@include bp(medium){
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		display: block;
	}
	@include bp(rev-small){
		.pos-content{
			margin: 0 -15px;
		}
	}
}