.detail-bar{
	@include bp(rev-medium){
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: block;
		transition: top 400ms ease-out;
		padding: 0 5px;
		&.detail-tab-block-scroll{
			position: fixed;
			top: 60px;
			left: 0;
			z-index: 99;
			&.hide-navigation{
				top: 0;
			}
		}
	}
}

.detail-tab-group{
	@include bp(rev-medium){
		position: relative;
		padding-top: 60px;
	}
}

.detail-side-panel{
	@include bp(rev-medium){
		margin-top: 3em;
	}
	.gallery-item{
		margin-bottom: 15px;
	}
}

.detail-side-list{

}

.detail-side-item{
	margin-bottom: 3em;
}

.detail-side-list-item{
	position: relative;
	padding-left: 25px;
	margin-bottom: 8px;
	line-height: 1.2;
	color: $color-black-normal;
	font-weight: 300;
	@include font-size($fs-20);
	&:before{
		content: '\e93a';
		position: absolute;
		top: 10px;
		left: 0;
		font-family: $project-name;
		color: $color-blue-normal;
		@include font-size($fs-8);
		transform: rotateY(180deg);
	}
}

.contact-side{
	display: flex;
	flex-wrap: wrap;
}

.contact-side-image-group{
	width: 90px;
	max-width: 90px;
	flex: 0 0 90px;
	@include bp(excl-xxlarge){
		width: 60px;
		max-width: 60px;
		flex: 0 0 60px;
	}
	@include bp(excl-xlarge){
		width: 60px;
		max-width: 60px;
		flex: 0 0 60px;
	}
	@include bp(excl-large){
		margin-bottom: 1em;
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
	}
}

.contact-side-text-group{
	padding-left: 15px;
	width: calc(100% - 90px);
	max-width: calc(100% - 90px);
	flex: 0 0 calc(100% - 90px);
	@include bp(excl-xxlarge){
		padding-left: 10px;
		width: calc(100% - 60px);
		max-width: calc(100% - 60px);
		flex: 0 0 calc(100% - 60px);
	}
	@include bp(excl-xlarge){
		padding-left: 10px;
		width: calc(100% - 60px);
		max-width: calc(100% - 60px);
		flex: 0 0 calc(100% - 60px);
	}
	@include bp(excl-large){
		padding-left: 0;
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
	}
}

.references-list{

}

.references-list-item{
	border-bottom: 1px solid #dddddd;
	margin-bottom: 20px;
	padding-bottom: 20px;
    color: $color-black-normal;
    font-weight: 400;
    line-height: 26px;
    @include font-size($fs-20);
    @include bp(rev-small) {
        @include font-size($fs-18);
    }
}


.shape-detail-1{
	position: absolute;
	top: 0;
	left: 10px;
	z-index: -1;
	transform: rotateZ(40deg);
	@include font-size(70px);
	@include bp(rev-medium){
		top: 0;
		left: -40px;
	}
}

.shape-detail-2{
	position: absolute;
	bottom: 100px;
	left: -10px;
	z-index: 5;
	transform: rotateZ(165deg);
	@include font-size(70px);
	@include bp(rev-medium){
		bottom: auto;
		top: -40px;
	}
}

.shape-detail-3{
	position: absolute;
	top: -70px;
	left: 50%;
	z-index: 5;
	@include font-size(170px);
	&:before{
		color: $color-blue-normal;
	}
	@include bp(rev-medium){
		top: -120px;
		left: auto;
		right: 5%;
	}
}

.shape-detail-4{
	position: absolute;
	bottom: -40px;
	right: 80px;
	z-index: 7;
	transform: rotateZ(30deg);
	@include font-size(170px);
	@include bp(rev-medium){
		display: none;
	}
}

.shape-detail-5{
	position: absolute;
	bottom: -10px;
	right: 150px;
	z-index: 5;
	@include font-size(100px);
	&:before{
		color: $color-blue-dark;
	}
	@include bp(rev-medium){
		bottom: -30px;
		right: 50px;
	}
}

.shape-detail-6{
	position: absolute;
	top: 70px;
	right: -80px;
	z-index: 2;
	transform: rotateZ(130deg);
	@include font-size(250px);
	&:before{
		color: $color-green-normal;
	}
	@include bp(rev-medium){
		top: -130px;
		right: -160px;
	}
}

.shape-detail-7{
	position: absolute;
	top: 260px;
	right: 40px;
	z-index: 3;
	transform: rotateZ(40deg);
	@include font-size(70px);
	@include bp(rev-medium){
		top: 50px;
		right: -50px;
	}
}

.shape-detail-8{
	position: absolute;
	top: 60%;
	left: 20px;
	z-index: -1;
	transform: rotateZ(20deg);
	@include font-size(70px);
	@include bp(rev-medium){
		top: 100px;
		left: -110px;
	}
}

.shape-detail-9{
	position: absolute;
	top: 55%;
	left: -140px;
	z-index: -1;
	transform: rotateZ(-40deg);
	@include font-size(250px);
	@include bp(rev-medium){
		top: 100px;
		left: -80px;
	}
}



.shape-description-1{
	position: absolute;
	top: -8px;
	right: -30px;
	z-index: -2;
	transform: rotateZ(8deg);
	@include font-size(60px);
	@include bp(rev-small){
		top: -10px;
		@include font-size(20px);
	}
}

.shape-description-2{
	position: absolute;
	top: 40%;
	left: -60px;
	z-index: -2;
	@include font-size(100px);
	@include bp(rev-small){
		left: 100px;
		@include font-size(40px);
	}
	&:before{
		color: $color-blue-normal;
	}
}


.shape-description-3{
	position: absolute;
	top: 48%;
	left: -10px;
	z-index: -3;
	transform: rotateZ(160deg);
	@include font-size(90px);
	@include bp(rev-small){
		@include font-size(70px);
	}
}

.shape-description-4{
	position: absolute;
	bottom: 20px;
	right: -30px;
	z-index: -2;
	transform: rotateZ(10deg);
	@include font-size(90px);
	@include bp(rev-small){
		left: 40px;
		@include font-size(30px);
	}
}