/* Map block */

.map-block{
	width: 100%;
	height: 100%;
	background-color: $color-orange-normal;
	position: relative;
	@include bp(rev-medium){
		height: 400px;
	}
}

.map{
	width: 100%;
	height: 100%;
}

.map-side{
	height: 360px;
	@include bp(rev-medium){
		height: 300px;
	}
	.map-block{
		height: 100%;
	}
}
