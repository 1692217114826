/* Web & icon fonts */

// @font-face {
//     font-family: $project-font-2;
//     src: url('../fonts/webfonts/fontname/fontname.eot');
//     src: url('../fonts/webfonts/fontname/fontname.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/webfonts/fontname/fontname.woff2') format('woff2'),
//         url('../fonts/webfonts/fontname/fontname.woff') format('woff'),
//         url('../fonts/webfonts/fontname/fontname.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: $project-font-1;
//     src: url('../fonts/webfonts/fontname/fontname-Bold.eot');
//     src: url('../fonts/webfonts/fontname/fontname-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/webfonts/fontname/fontname-Bold.woff2') format('woff2'),
//         url('../fonts/webfonts/fontname/fontname-Bold.woff') format('woff'),
//         url('../fonts/webfonts/fontname/fontname-Bold.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }


@font-face {
	font-family: $project-name;
	src:  url('../fonts/iconfonts/icomoon.eot?du7nse');
	src:  url('../fonts/iconfonts/icomoon.eot?du7nse#iefix') format('embedded-opentype'),
		url('../fonts/iconfonts/icomoon.ttf?du7nse') format('truetype'),
		url('../fonts/iconfonts/icomoon.woff?du7nse') format('woff'),
		url('../fonts/iconfonts/icomoon.svg?du7nse#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: $project-name !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-cog:before {
  content: "\e93f";
}
.icon-birthday-cake:before {
  content: "\e93e";
}
.icon-minus:before {
  content: "\e934";
}
.icon-plus:before {
  content: "\e933";
}
.icon-facebook-square:before {
  content: "\e932";
}
.icon-eye:before {
  content: "\e931";
}
.icon-calendar:before {
  content: "\e930";
}
.icon-play-circle-o:before {
  content: "\e92f";
}
.icon-map-marker:before {
  content: "\e92b";
}
.icon-clock-o:before {
  content: "\e92a";
}
.icon-envelope:before {
  content: "\e929";
}
.icon-mobile:before {
  content: "\e927";
}
.icon-phone:before {
  content: "\e928";
}
.icon-chevron-right:before {
  content: "\e923";
}
.icon-chevron-left:before {
  content: "\e924";
}
.icon-chevron-down:after {
  content: "\e925";
}
.icon-chevron-up:after {
  content: "\e926";
}
.icon-youtube-play:before {
  content: "\e922";
}
.icon-facebook:before {
  content: "\e921";
}
.icon-instagram:before {
  content: "\e920";
}
.icon-S .path1:before {
  content: "\e943";
  color: rgb(255, 255, 255);
}
.icon-S .path2:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-S .path3:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-S .path4:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-S .path5:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-S .path6:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-E .path1:before {
  content: "\e949";
  color: rgb(43, 112, 183);
}
.icon-E .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-E .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-E .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-E .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-A .path1:before {
  content: "\e94e";
  color: rgb(0, 0, 0);
}
.icon-A .path2:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-A .path3:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-A .path4:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-A .path5:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-L-1 .path1:before {
  content: "\e953";
  color: rgb(0, 0, 0);
}
.icon-L-1 .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-L-1 .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-L-1 .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-L-1 .path5:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-P .path1:before {
  content: "\e958";
  color: rgb(233, 84, 37);
}
.icon-P .path2:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(233, 84, 37);
}
.icon-P .path3:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-P .path4:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-P .path5:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-V .path1:before {
  content: "\e95d";
  color: rgb(233, 84, 37);
}
.icon-V .path2:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(233, 84, 37);
}
.icon-V .path3:before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-V .path4:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-V .path5:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(233, 84, 37);
}
.icon-U .path1:before {
  content: "\e962";
  color: rgb(30, 43, 89);
}
.icon-U .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-U .path3:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-U .path4:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-U .path5:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Z .path1:before {
  content: "\e967";
  color: rgb(255, 221, 0);
}
.icon-Z .path2:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Z .path3:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-Z .path4:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-Z .path5:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-J .path1:before {
  content: "\e96c";
  color: rgb(255, 221, 0);
}
.icon-J .path2:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-J .path3:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-J .path4:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(0, 170, 131);
}
.icon-J .path5:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-J .path6:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(0, 170, 131);
}
.icon-L .path1:before {
  content: "\e972";
  color: rgb(0, 0, 0);
}
.icon-L .path2:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(0, 170, 131);
}
.icon-L .path3:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(0, 170, 131);
}
.icon-L .path4:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-L .path5:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-L .path6:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(0, 170, 131);
}
.icon-D .path1:before {
  content: "\e978";
  color: rgb(43, 112, 183);
}
.icon-D .path2:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-D .path3:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-D .path4:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-D .path5:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-D .path6:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(43, 112, 183);
}
.icon-D .path7:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-male:before {
  content: "\e941";
  color: #fff;
}
.icon-female:before {
  content: "\e942";
  color: #fff;
}
.icon-scroll:before {
  content: "\e940";
}
.icon-rectangle-skew:before {
  content: "\e93b";
  color: #2270b8;
}
.icon-rectangle-skew-1:before {
  content: "\e93c";
  color: #fbdf06;
}
.icon-rectangle-skew-2:before {
  content: "\e93d";
  color: #00aa82;
}
.icon-rectangle-blue:before {
  content: "\e93a";
  color: #2270b8;
}
.icon-shape-line:before {
  content: "\e92c";
  color: #1e2c59;
}
.icon-shape-pipe:before {
  content: "\e92d";
  color: #fcdc00;
}
.icon-shape-circle-green:before {
  content: "\e92e";
  color: #00aa82;
}
.icon-shape-circle-yellow:before {
  content: "\e935";
  color: #fcdc00;
}
.icon-shape-triangle-green:before {
  content: "\e936";
  color: #00aa82;
}
.icon-shape-square-green:before {
  content: "\e937";
  color: #00aa82;
}
.icon-shape-square-yellow:before {
  content: "\e938";
  color: #fcdc00;
}
.icon-shape-rectangle-blue:before {
  content: "\e939";
  color: #2a70b7;
}
.icon-wachumba-logo:before {
  content: "\e900";
  color: #e95424;
}
.icon-phone-icon .path1:before {
  content: "\e901";
  color: rgb(251, 223, 6);
}
.icon-phone-icon .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone-icon .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone-icon .path4:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone-icon .path5:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone-icon .path6:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone-icon .path7:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-2 .path1:before {
  content: "\e908";
  color: rgb(44, 113, 182);
}
.icon-2 .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-2 .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-2 .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-2 .path5:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(44, 113, 182);
}
.icon-2 .path6:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(30, 43, 89);
}
.icon-3 .path1:before {
  content: "\e90e";
  color: rgb(233, 84, 37);
}
.icon-3 .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(44, 113, 182);
}
.icon-3 .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(233, 84, 37);
}
.icon-3 .path4:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-3 .path5:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(233, 84, 37);
}
.icon-3 .path6:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(233, 84, 37);
}
.icon-3 .path7:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-3 .path8:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(44, 113, 182);
}
.icon-3 .path9:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(44, 113, 182);
}
.icon-icon-facebook:before {
  content: "\e917";
  color: #e95424;
}
.icon-icon-instagram:before {
  content: "\e918";
  color: #e95424;
}
.icon-icon-youtube:before {
  content: "\e919";
  color: #e95424;
}
.icon-1 .path1:before {
  content: "\e91a";
  color: rgb(255, 221, 0);
}
.icon-1 .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(44, 113, 182);
}
.icon-1 .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(29, 43, 89);
}
.icon-1 .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(29, 43, 89);
}
.icon-1 .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 221, 0);
}
.icon-1 .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(44, 113, 182);
} 