/* Checkbox helpers */

.checkbox{
    clear: both;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 0;
}

.checkbox-small{
    margin-bottom: 15px;
    .checkbox-text{
        @include font-size($fs-14);
    }
}

.checkbox-hidden {
    display: none;
    // position: absolute;
    // top: -9999px;
    // left: -9999px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
    overflow: hidden;
    visibility: visible;
}

.checkbox-label {
    margin: 0;
    padding-left: 0!important;
    min-height: inherit;
    width: 100%;
    display: block;
    color: black;
    vertical-align: middle;
    padding-top: 0;
    cursor: pointer;
    text-transform: none;
}

.checkbox-box {
    float: left;
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid #999999;
    top: 2px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 0;
}

.checkbox-text {
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
    height: 100%;
    vertical-align: middle;
    font-weight: 200;
    overflow: hidden;
    margin-left: 0;
    line-height: 1.2;
    padding-bottom: 1px;
    color: $color-black-normal;
    @include font-size($fs-20);
    a{
        text-decoration: underline;
    }
}

.checkbox-right{
    width: 25%;
    text-align: right;
    float: right;
    @include bp(rev-small){
        width: 30%
    }
}

.checkbox-left{
    float: left;
    width: 75%;
    padding-right: 15px;
    @include bp(rev-small){
        width: 70%;
    }
}

.checkbox-img{
    display: inline-block;
    position: relative;
}

.checkbox-hidden:checked + .checkbox-label {
    .checkbox-box {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 1px;
            left: 5px;
            width: 5px;
            height: 10px;
            border: solid $color-black-normal;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
        }
    }
}

.radiobox{
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    .checkbox-box {
        width: 16px;
        height: 16px;
        border: 1px solid #999999;
        background-color: #ffffff;
        @include border-radius(100%);
    }
    .checkbox-hidden:checked + .checkbox-label {
        .checkbox-box {
            border: 0;
            background-color: $color-yellow-normal;
            &:after {
                top: 2px;
                left: 5px;
                width: 6px;
                height: 10px;
                border: solid #ffffff;
                border-width: 0 2px 2px 0;
            }
        }
    }
}

