/* Tabs block */

.tabs-group{
}

.tabs-list{
    border: 0;
    text-align: center;
}

.tabs-list-item{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50%;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: 0;
    float: left;
    &.is-active{
        .tabs-list-text{
            color: rgba(255,255,255, 1);
        }
    }
    &.is-complete{
        .tabs-list-check{
            display: inline-block;
        }
    }
}

.tabs-list-text{
    display: inline-block;
    position: relative;
    text-align: center;
    font-weight: 900;
    padding: 0 10px;
    line-height: 1.2;
    color: rgba(255,255,255,0.3);
    @include font-size($fs-36);
    @include bp(rev-xlarge){
        @include font-size($fs-30);
    }
    @include bp(rev-small){
        @include font-size($fs-28);
    }
    @include bp(rev-xsmall){
        @include font-size($fs-24);
    }
    @include bp(rev-xssmall){
        @include font-size($fs-22);
    }
}

.tabs-list-detail{
    @include bp(small){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    @include bp(rev-small){
        overflow: auto;
        white-space: nowrap;
        padding-right: 50px;
    }
}

.tabs-list-detail-small{
    justify-content: flex-start;
    .tabs-list-item-detail{
        &.is-active{
            .tabs-list-text-detail{
                color: $color-orange-normal;
            }
        }
        .tabs-list-text-detail{
            &:after{
                background: $color-orange-normal;
            }
        }
    }
    .tabs-list-text-detail{
        padding: 20px 15px;
        color: $color-black-normal;
        @include bp(rev-medium){
            padding: 20px 13px;
        }
    }
}

.tabs-list-item-detail{
    height: 90px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: 0;
    @include bp(medium){
        display: flex;
        align-items: center;
        justify-content: center;
        // width: calc(100% / 7);
    }
    @include bp(rev-medium){
        display: inline-block;
        vertical-align: middle;
        height: 60px;
    }
    &.is-active{
        .tabs-list-text-detail{
            font-weight: 900;
            color: $color-yellow-normal;
            &:after{
                opacity: 1;
                // width: 100%;
            }
        }
    }
}

.tabs-list-text-detail{
    display: inline-block;
    text-align: center;
    font-weight: 400;
    padding: 20px 10px;
    text-transform: uppercase;
    line-height: 1.2;
    color: rgba(255,255,255,1);
    @include font-size($fs-18);
    @include bp(rev-large){
        padding: 20px 5px;
        @include font-size($fs-16);
    }
    @include bp(rev-medium){
        padding: 20px 3px;
        @include font-size($fs-14);
    }
    @include bp(rev-small){
        padding: 20px 8px;
        @include font-size($fs-16);
    }
    &:after{
        content: '';
        position: absolute;
        display: block;
        height: 6px;
        width: 100%;
        bottom: 0;
        left: 0;
        opacity: 0;
        background: $color-yellow-normal;
        transition: opacity 250ms ease-out;
    }
}

.tabs-list-item-count{
    position: absolute;
    top: 10px;
    right: 0;
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 1;
    border-radius: 100%;
    font-weight: 900;
    color: #ffffff;
    @include font-size($fs-18);
    background-color: $color-blue-dark;
    @include bp(rev-medium){
        top: 5px;
    }
}

.tabs-list-check{
    display: none;
    border-radius: 100px;
    background-color: #ffffff;
    text-align: center;
    width: 22px;
    height: 22px;
    position: relative;
    margin-left: 8px;
    &:before{
        content: '';
        position: absolute;
        top: 5px;
        left: 8px;
        display: block;
        position: absolute;
        width: 6px;
        z-index: 2;
        height: 10px;
        border: solid $color-green-normal;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(40deg);
        transform: rotate(40deg);
    }
}

.tabs-content{
    clear: both;
}

.tabs-content-item{
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
    &.is-active{
        height: auto;
        opacity: 1;
        overflow: visible;
        visibility: visible;
        -webkit-transition: opacity 250ms ease;
        -moz-transition: opacity 250ms ease;
        transition: opacity 250ms ease;
    }
}

.scroll-icon{
    &:before{
        content: '';
        display: none;
        position: absolute;
        bottom: 0;
        height: 60px;
        right: 0;
        width: 60px;
        text-align: center;
        background-color: $color-green-normal;
        z-index: 2;
        @include bp(rev-small){
            display: block;
        }
    }
    &:after{
        content: '\e940';
        display: none;
        font-family: $project-name;
        position: absolute;
        bottom: 15px;
        right: 2px;
        color: #ffffff;
        -webkit-animation: slideOutLeft 2s infinite;
        -moz-animation:    slideOutLeft 2s infinite; 
        -o-animation:      slideOutLeft 2s infinite;
        animation:         slideOutLeft 2s infinite; 
        z-index: 3;
        @include font-size($fs-32);
        @include bp(rev-small){
            display: block;
        }
    }
}

.detail-tab-block-gray{
    &:before{
        background-color: $color-light-gray;
    }
    &:after{
        color: $color-orange-normal;
    }
}