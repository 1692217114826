.address-group{
	margin-bottom: 2em;
}

.address-item{
	margin-bottom: 0.5em;
}

.address-item-small{
	.address-link{
		line-height: 1.2;
		@include font-size($fs-20);
	}
	.address-icon{
		margin-right: 8px;
		top: 6px;
		@include font-size($fs-14);
		&.icon-mobile{
			@include font-size($fs-18);
		}
	}
}

.address-icon{
	float: left;
	position: relative;
	width: 20px;
	margin-right: 15px;
	text-align: center;
	color: $color-green-normal;
	top: 6px;
	@include font-size($fs-18);
	@include bp(medium){
		top: 8px;
		@include font-size($fs-22);
	}
}

.address-link-block{
	display: block;
	height: 100%;
	overflow: hidden;
}

.address-link{
	display: inline-block;
	vertical-align: middle;
	color: $color-black-normal;
	font-weight: 400;
	line-height: 1.4;
	margin-right: 5px;
	@include font-size($fs-20);
	@include bp(small){
		@include font-size($fs-22);
	}
	@include bp(medium){
		@include font-size($fs-26);
	}
	&[href]:hover{
		color: $color-green-normal;
	}
}