.hero{
	.js-slide-item{
		padding: 15px 0;
	}
}

.hero-title{
	display: block;
	margin-bottom: 0.2em;
	color: $color-blue-dark;
	font-weight: 900;
	line-height: 1;
	text-transform: uppercase;
	@include font-size(42px);
	@include bp(small){
		@include font-size(50px);
	}
	@include bp(medium){
		@include font-size(62px);
	}
}

.hero-subtitle{
	display: block;
	margin-bottom: 0.8em;
	color: #2a70b7;
	font-weight: 900;
	line-height: 1;
	@include font-size(32px);
	@include bp(small){
		@include font-size(36px);
	}
	@include bp(medium){
		@include font-size(40px);
	}
}

.hero-btn{
	@include font-size(18px);
}


.hero-video-group{
	display: block;
	position: absolute;
	background-color: black;
	height: 100%;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
}

video {
    position: absolute;
    // z-index: -100;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    // min-width: 100%;
    width: 120%;
    // height: auto;
    overflow: hidden;
    // background: url(../images/hero-bg.jpg) center no-repeat;
    // background-size: cover;
    transition: 1s opacity;
    @include bp(rev-xxlarge){
    	// height: 100vh;
    	width: 120%;
    }
    @include bp(rev-xlarge){
    	width: 140%;
    }
    @include bp(rev-large){
    	width: 180%;
    }
}