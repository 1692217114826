.js-gallery{
	&:hover{
		.gallery-icon{
			color: $color-green-normal;
		}
	}
}

.gallery-icon{
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	margin-top: -40px;
	margin-left: -35px;
	color: #ffffff;
	// box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
	@include font-size(80px);
	z-index: 4;
	@include bp(rev-small){
		margin-left: -25px;
		margin-top: -30px;
		@include font-size(60px);
	}
}

.gallery-group{
	.js-gallery-item{
		display: block;
		overflow: hidden;
		margin-bottom: 30px;
		background-color: $color-orange-normal;
		@include bp(rev-xsmall){
			margin-bottom: 10px;
		}
	}
	.js-slide-item{
		padding: 0 5px;
	}
}

.image-hover{
	overflow: hidden;
	&:hover{
		img{
			transform: scale(1.03,1.03);
		}
	}
	img{
		transition: transform 400ms ease-out;
	}
}