.select2-container{
	z-index: 7;
	&.select2-container--open,
	.select2-container--focus{
		.select2-selection--single,
		.select2-selection--multiple{
			.select2-selection__arrow{
			 	&:after{
			 		@include transform(rotate(-180deg));
				}
			}
		}
	}
	.select2-search--inline .select2-search__field{
		margin-top: 0;
	}

	.select2-search--inline{
		display: block;
		width: 100%;
	}



	.select2-selection--single,
	.select2-selection--multiple{
		width: 100%;
		letter-spacing: 0px;
		padding: 0 25px 1px 20px;
		height: 50px;
		padding-bottom: 1px;
		width: 100%;
		background-color: $color-light-gray;
		color: $color-black-normal;
		@include font-size(20px);
		line-height: 1.2;
        border: 0;
        outline: none!important;
		@include border-radius(0);
		.select2-selection__rendered{
			line-height: 50px;
			padding: 0;
			color: $color-black-normal;
		}
		.select2-selection__placeholder{
			color: $color-black-normal;
		}
		.select2-selection__arrow{
			position: absolute;
			height: 50px;
			line-height: 50px;
			top: 0;
			right: 0px;
			width: 30px;
			text-align: center;
			&:after{
				content: "\e925"!important;
				font-family: $project-name;
				display: block;
				font-weight: 400;
				width: 100%;
				height: 100%;
				@include font-size(14px);
			}
			b{
				display: none;
			}
		}
	}
}

.select2-container .select2-container--focus .select2-selection--single .select2-selection__arrow:after, 
.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow:after{
	content: "\e91a";
}



.select2-dropdown{
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
    background-color: #e8e8e8;
    border: 0;
	.select2-results__options--nested .select2-results__option{
		padding: 0 20px;
	}

	.select2-results__options{
		max-height: 220px!important;
		.select2-results__option[aria-selected=true]{
			background-color: #d5d4d4;
			color: $color-black-normal;
			@include font-size(20px);
		}
		.select2-results__option{
			padding: 12px 20px;
			color: $color-black-normal;
			@include font-size(20px);
		}
		.select2-results__option--highlighted{
			color: $color-black-normal;
			background-color: #d5d4d4;
		}
	}
}