/* Hidden content helpers */

.js-hidden-content{
	height: 0;
	width: 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	-webkit-transition: opacity 250ms ease;
	-moz-transition: opacity 250ms ease;
	transition: opacity 250ms ease;
	&.is-active{
		width: auto;
		height: auto;
		opacity: 1;
		overflow: visible;
		visibility: visible;
		-webkit-transition: opacity 250ms ease;
		-moz-transition: opacity 250ms ease;
		transition: opacity 250ms ease;
	}
}

.js-hidden-md-content{
	@include bp(rev-medium){
		height: 0;
		opacity: 0;
		visibility: hidden;
		overflow: hidden;
		-webkit-transition: opacity 250ms ease;
		-moz-transition: opacity 250ms ease;
		transition: opacity 250ms ease;
		&.is-active{
			height: auto;
			opacity: 1;
			overflow: visible;
			visibility: visible;
			-webkit-transition: opacity 250ms ease;
			-moz-transition: opacity 250ms ease;
			transition: opacity 250ms ease;
		}
	}
}

.js-hidden-block-ext{
	.js-hidden-content{
		display: none;
		&.is-active{
			display: block;
		}
	}
}