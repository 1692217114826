/* Accordion block */

.js-accordion-group{
}

.js-accordion-group-arrows{
    .js-accordion-trigger{
        padding: 10px 10px 10px 30px;
        &:after{
            content: '\e923';
            left: 0;
            color: #ffffff;
        }
    }
    .js-accordion-item{
        &.is-active{
            background-color: transparent;
            .js-accordion-trigger:after{
                content: '\e925';
            }
            .js-accordion-content{
                padding: 0 0 10px 30px;
            }
        }
    }
}

.js-accordion-item{
    position: relative;
    z-index: 2;
    &.is-active{
        background-color: $color-light-gray;
        .js-accordion-trigger{
            @include transition(none);
            &:after{
                content: "\e934";
            }
        }
        .js-accordion-content{
            width: auto;
            height: auto;
            opacity: 1;
            visibility: visible;
            overflow: visible;
            padding: 0 20px 10px 70px;
            @include transition(opacity 250ms ease);
            @include bp(rev-small){
                padding: 0 10px 10px 40px;
            }
        }
    }
}

.js-accordion-trigger{
    margin-bottom: 0;
    display: block;
    position: relative;
    cursor: pointer;
    padding: 20px 20px 20px 70px;
    @include transition(none);
    @include bp(rev-small){
        padding: 13px 10px 13px 40px;
    }
    &:after{
        content: '\e933';
        font-family: $project-name;
        position: absolute;
        left: 20px;
        top: 50%;
        display: block;
        line-height: 1;
        @include font-size($fs-16);
        color: $color-black-normal;
        @include transform(translateY(-50%));
        @include bp(rev-small){
            left: 12px;
        }
    }
}

.js-accordion-content{
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    border-top: 0;
    @include transition(opacity 250ms ease);
}

.faq{
    .js-accordion-item{
        border-bottom: 1px solid $color-light-gray;
        &:last-child{
            border-bottom: 0;
        }
    }
    .delta{
        font-weight: 600;
    }
}


