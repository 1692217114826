/* Social block */

.social-list{
}

.social-list-item{
	display: inline-block;
	vertical-align: middle;
	margin-right: 15px;
	&:last-child{
		margin-right: 0;
	}
}

.social-list-link{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	color: #ffffff;
	background-color: $color-orange-normal;
	border-radius: 100%;
	@include font-size($fs-18);
	&:hover{
		background-color: $color-black-normal;
	}
}
