
/* Base settings */

*,
*::before,
*::after {
  box-sizing: border-box;
}


*{
    transition: color .15s, background .15s, border .15s, opacity .15s;
}

*:after, *:before  {
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

@-ms-viewport {
    width: device-width;
}

html{
    position: relative;
    min-height: 100%;
    box-sizing: border-box;
    font-size: 100%;
    @include bp(excl-xxlarge){
        font-size: 80%;
    }
    @include bp(excl-xlarge){
        font-size: 80%;
    }
    @include bp(excl-large){
        font-size: 80%;
    }
}

body {
    margin: 0;
    height: 100%;
    min-width: 310px;
    font-family: $font-primary;
    font-weight: 400;
    line-height: 1;
    background-color: #ffffff;
}

a{
    font-family: $font-primary;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    color: inherit;
} 

a:focus,
a:active,
a:visited{
    outline: none;
}

.a-hover{
    &:hover, &:focus {
        // text-decoration: underline;
        // color: white;
    }
}

.a-hover{
    display: inline-block;
    vertical-align: middle;
    .a-hover-icon{
        margin-right: 5px;
        color: $color-black-normal;
        @include font-size($fs-10);
    }
    .a-hover-text{
        font-family: $font-primary;
        color: $color-black-normal;
        font-weight: 400;
        line-height: 1.2;
        @include font-size($fs-16);
    }
}

.a-hover-blue{
    .a-hover-icon{
        color: $color-blue-normal;
    }
    .a-hover-text{
        color: $color-blue-normal;
        @include font-size($fs-20);
        @include bp(rev-small){
            @include font-size($fs-18);
        }
    }
}

.a-hover-absolute{
    margin-bottom: 15px;
    @include bp(medium){
        position: absolute;
        top: 110px;
        left: 0;
        margin-bottom: 0;
    }
}

.a-hover-absolute-ext{
    margin-bottom: 45px;
    @include bp(medium){
        position: absolute;
        top: 90px;
        left: 0;
        margin-bottom: 0;
    }
}

p{
    margin-top: 0;
    margin-bottom: 1.5em;
    color: $color-black-normal;
    font-weight: 400;
    line-height: 26px;
    @include font-size($fs-20);
    @include bp(rev-small) {
        @include font-size($fs-18);
    }
    a{
        font-weight: 700;
        color: $color-blue-normal;
    }
} 

.p-big p{
    line-height: 36px;
    @include font-size($fs-26);
    @include bp(rev-small){
        @include font-size($fs-22);
    }
    @include bp(rev-xsmall){
        @include font-size($fs-18);
    }
}

.p-normal p{
    line-height: 28px;
    @include font-size($fs-18);
    @include bp(rev-small){
        @include font-size($fs-16);
    }
}

.p-small p{
    line-height: 22px;
    @include font-size($fs-14);
}


ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

em, i, .italic {
    font-style: italic;
}

strong, .strong {
    font-weight: 900;
}

small, .small {
}


h1, .alfa,
h2, .beta,
h3, .gamma,
h4, .delta,
h5, .epsilon,
h6, .zeta {
    font-family: $font-primary;
    font-weight: 900;
    color: $color-black-normal;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 1em;
}

h1, .alfa {
    line-height: 1.2;
    margin-bottom: 0.6em;
    @include font-size(32px);
    @include bp(xssmall) {
        @include font-size(34px);
    }
    @include bp(xsmall) {
        @include font-size(42px);
    }
    @include bp(small) {
        @include font-size(48px);
    }
    @include bp(medium) {
        @include font-size(52px);
    }
    @include bp(large) {
        @include font-size(56px);
    }
    
}

h2, .beta {
    margin-bottom: 0.5em;
    @include font-size(28px);
    @include bp(xssmall) {
        @include font-size(32px);
    }
    @include bp(xsmall) {
        @include font-size(36px);
    }
    @include bp(small) {
        @include font-size(38px);
    }
    @include bp(medium) {
        @include font-size(42px);
    }
    @include bp(large) {
        @include font-size(46px);
    }
}

h3, .gamma {
    @include font-size($fs-26);
    
    @include bp(xsmall) {
        @include font-size($fs-28);
    }
    @include bp(small) {
        @include font-size($fs-36);
    }
}

.gamma-ext{
    @include font-size($fs-24);
    
    @include bp(xsmall) {
        @include font-size($fs-26);
    }
    @include bp(small) {
        @include font-size($fs-32);
    }
}

h4, .delta {
    margin-bottom: 0.5em;
    @include font-size($fs-20);
    @include bp(xsmall) {
        @include font-size($fs-22);
    }
    @include bp(small) {
        @include font-size($fs-26);
    }
}

.delta-ext{
    margin-bottom: 0.3em;
    font-weight: 300;
}

.delta-semi{
    margin-bottom: 0.8em;
    font-weight: 600;
}

h5, .epsilon {
    @include font-size($zeta);
    
    @include bp(small) {
        @include font-size($epsilon);
    }
}

h6, .zeta {
    @include font-size($zeta);
}

