.product-group-green{
	.product-content{
		&:hover{
			.gamma{
				color: $color-green-normal;
			}
		}
	}
	.product-image-content{
		background-color: $color-green-normal;
	}
}

.products-group-more{
	.product-image-group{
		margin-bottom: 2em;
	}
	.product-item{
		margin-bottom: 0;
		@include bp(large){
			padding: 0 40px;
		}
	}
}

.product-item{
	margin-bottom: 2em;
	@include bp(rev-xsmall){
		margin-bottom: 4em;
	}
	&:nth-child(3n + 2){
		.product-bg{
			fill: $color-green-normal;
		}
	}
	&:nth-child(3n + 3){
		.product-bg{
			fill: $color-blue-dark;
		}
	}

	// &.bg-blue-dark .product-bg{
	// 	fill: $color-blue-dark;
	// }
	// &.bg-green .product-bg{
			// fill: $color-blue-normal;
	// }
}

.product-content{
	&:hover{
		.gamma{
			color: $color-orange-normal;
		}
		img{
			opacity: 0.5;
		}
		.product-btn{
			@extend .swing;
		}
	}
}

.product-image-content{
	height: 100%;
	background-color: $color-orange-normal;
}

.product-image-group{
	position: relative;
	@include bp(rev-xsmall){
		margin-bottom: 25px;
	}
}

.product-text-group{
	.gamma{
		margin-bottom: 0.5em;
		max-height: 80px;
		@include font-size($fs-24);
		@include bp(small){
			@include font-size($fs-26);
		}
		@include bp(medium){
			@include font-size($fs-28);
		}
		@include bp(large){
			@include font-size($fs-32);
		}
	}
	p{
		margin-bottom: 1em;
		max-height: 80px;
		@include font-size($fs-16);
		@include bp(small){
			@include font-size($fs-18);
		}
	}
}

.product-tag{
	position: absolute;
	top: 10px;
	left: 0;
	display: block;
	padding: 20px 25px;
	color: #ffffff;
	font-weight: 900;
	line-height: 1;
	text-transform: uppercase;
	@include font-size($fs-24);
	z-index: 4;
	@include bp(rev-medium){
		@include font-size($fs-20);
	}
	@include bp(rev-small){
		@include font-size($fs-18);
	}
	@include bp(rev-xsmall){
		top: 5px;
		padding: 10px 20px;
		@include font-size($fs-14);
	}
	// &:after{
	// 	content: '\e93b';
	// 	font-family: $project-name;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	z-index: -1;
	// 	@include font-size(46px);
	// 	color: $color-green-normal;
	// }
}

.product-tag-bg{
	position: absolute;
	top: 5px;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	@include bp(rev-xsmall){
		top: 0;
	}
}

.product-place{
	display: block;
	margin-bottom: 5px;
	color: $color-black-normal;
	font-weight: 400;
	line-height: 1.2;
	@include font-size($fs-18);
}

.product-info-list{
	margin-bottom: 10px;
}

.product-info-list-item{
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
	margin-bottom: 10px;
	@include bp(xlarge){
		margin-right: 40px;
	}
	&:last-child{
		margin-right: 0;
	}
}

.product-info-list-icon{
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	color: $color-black-normal;
	@include font-size($fs-18);
}

.product-info-list-text{
	display: inline-block;
	vertical-align: middle;
	color: $color-black-normal;
	font-weight: 400;
	line-height: 1.2;
	@include font-size($fs-14);
	@include bp(small){
		@include font-size($fs-16);
	}
}

.product-btn{
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	@include bp(rev-large){
		min-width: 140px;
	}
	@include bp(large){
		margin-right: 30px;
	}
	@include bp(rev-xssmall){
		min-width: 120px;
		padding: 18px 5px;
	}
}

.product-price{
	position: relative;
	top: -5px;
	display: inline-block;
	vertical-align: middle;
	color: $color-black-normal;
	font-weight: 900;
	line-height: 1;
	@include font-size($fs-28);
	@include bp(small){
		@include font-size($fs-36);
	}
	@include bp(rev-xssmall){
		margin-top: 10px;
		@include font-size($fs-20);
	}
	small{
		@include font-size($fs-22);
	}
}

.product-status{
	margin: 12px 0 12px 25px;
	display: inline-block;
	vertical-align: middle;
	color: $color-orange-normal;
	font-weight: 900;
	line-height: 1;
	@include font-size($fs-16);
	@include bp(rev-large){
		margin: 12px 0 12px 10px;
	}
}

