/* Footer */

.footer{
	position: relative;
    z-index: 9;
    background: #ffffff;
}

.footer-item{
	position: relative;
	margin-bottom: 60px;
	&:after{
		content: '';
		display: block;
		position: absolute;
		background-color: #e8e8e8;
		@include bp(xsmall){
			top: 0;
			right: 30px;
			width: 1px;
			height: 100%;
		}
		@include bp(rev-small){
			right: 5px;
		}
		@include bp(rev-xsmall){
			bottom: -20px;
			right: 0;
			width: 100%;
			height: 1px;
		}
	}
	&:last-child:after{
		display: none;
	}
	@include bp(rev-large){
		&:nth-child(2){
			&:after{
				display: none;
			}
		}
	}
}

.footer-title{
	display: block;
	margin-bottom: 0.4em;
	color: $color-orange-normal;
	@include font-size($fs-22);
	font-weight: 900;
	line-height: 1.4;
}

.footer-top{
	padding: 100px 10px 0;
	@include bp(rev-xlarge){
		padding-top: 0;
	}
}

.footer-logo{

}

.footer-bottom{
	padding: 15px 10px 15px;
	color: $color-black-normal;
	line-height: 1.4;
	@include font-size($fs-16);
	font-weight: 400;
	background-color: $color-white-light;
}

.footer-middle{
	padding: 40px 10px 40px;
	@include bp(rev-xsmall){
		padding: 15px 10px;
	}
}

.footer-copy{
	position: relative;
	padding: 0 15px;
	margin-bottom: 10px;
	@include bp(rev-xsmall){
		display: block;
	}
	@include bp(xsmall){
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			width: 1px;
			height: 80%;
			transform: translateY(-50%);
			background-color: $color-black-normal;
		}
	}
}

.footer-link{
	color: $color-black-normal;
	&:hover{
		color: $color-orange-normal;
	}
}

.footer-author{
	padding: 0 15px;
	margin-bottom: 10px;
}

.footer-item-border{
	@include bp(medium){
		margin-right: 20px;
		border-right: 1px solid #f2f2f2;
	}
	@include bp(large){
		margin-right: 60px;
	}
}

.footer-contact-list{
	margin-top: 20px;
}

.footer-contact-list-item{
	display: block;
	margin-bottom: 5px;
}

.footer-contact-list-link{
	color: $color-black-normal;
	padding: 5px 0;
	font-weight: 900;
	line-height: 1.2;
	@include font-size($fs-18);
	@include bp(xsmall){
		@include font-size($fs-20);
	}
	&:hover{
		color: $color-orange-normal;
	}
}

.footer-menu-list{
	@include bp(xsmall){
		column-count: 2;
	}
}

.footer-menu-item{
	display: block;
	@include bp(xsmall){
		margin-bottom: 5px;
	}
	&:last-child{
		margin-bottom: 0;
	}
}

.footer-menu-link{
	display: inline-block;
	padding: 3px 0;
	color: $color-black-normal;
	line-height: 1.4;
	font-weight: 400;
	@include font-size($fs-16);
	&:hover{
		color: $color-orange-normal;
	}
}

