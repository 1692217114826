/* Print */

@media print {    
    h2, h3, h4 {
        page-break-after: avoid;
    }

    img {
        max-width: 100% !important;
    }
    
    ul, img {
        page-break-inside: avoid;
    }
    
    .hidden-print {
        display: none;
    }
}