/* Size helpers */

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}

.w-quarter {
    width: 25%;
}


.h-full {
    height: 100%;
}

.h-half {
    height: 50%;
}

.h-quarter {
    height: 25%;
}

.h-sm-200{
	@include bp(rev-small){
		height: 200px;
	}
}

.h-md-200{
	@include bp(rev-medium){
		height: 200px;
	}
}

.h-md-300{
	@include bp(rev-medium){
		height: 300px;
	}
}

.h-md-400{
	@include bp(rev-medium){
		height: 400px;
	}
}