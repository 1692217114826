/* Content */

section {
    position: relative;
    z-index: 2;
    padding: 60px 10px 60px;
    // background-color: white;
    @include bp(rev-small){
        padding: 60px 10px 60px;
    }
}

.main-people-section{
    height: 350px;
    @include bp(small){
        height: 400px;
    }
    @include bp(medium){
        height: 630px;
    }
}

.no-padding{
    padding: 0;
}

.padding-bg{
    padding: 40px 10px 150px;
}

// padding style
.padding-style-xsmall{
    padding: 20px 10px 20px;
    @include bp(rev-xsmall){
        padding: 40px 10px 40px;
    }
}

.padding-style-small{
    padding: 40px 10px 40px;
    @include bp(rev-xsmall){
        padding: 40px 10px 40px;
    }
}

.padding-style-normal{
    padding: 60px 10px 60px;
    @include bp(rev-xsmall){
        padding: 40px 10px 40px;
    }
}

.padding-style-big{
    padding: 80px 10px 80px;
    @include bp(rev-xsmall){
        padding: 60px 10px 40px;
    }
}


// padding-custom
.padding-custom-1{
    padding: 180px 10px 100px 10px;
    @include bp(small){
        padding: 180px 10px 150px 10px;
    }
    @include bp(medium){
        padding: 180px 10px 200px 10px;
    }
}

.padding-custom-2{
    z-index: 4;
    padding: 20px 10px 20px 10px;
    @include bp(small){
        padding: 20px 10px 20px 10px;
    }
    @include bp(xxlarge){
        padding: 20px 10px 20px 10px;
    }
}

.padding-custom-3{
    z-index: 4;
    padding: 40px 0 90px 0;
    @include bp(medium){
        padding: 40px 0 40px 0;
    }
    @include bp(xxlarge){
        padding: 40px 0 80px 0;
    }
}

.padding-custom-4{
    padding: 90px 10px 150px;
    @include bp(rev-small){
        padding: 40px 10px 150px;
    }
}

.padding-custom-5{
    padding: 100px 10px 150px 10px;
    @include bp(small){
        padding: 100px 10px 200px 10px;
    }
    @include bp(xxlarge){
        padding: 100px 10px 200px 10px;
    }
}

.padding-custom-6{
    padding: 100px 20px 150px 20px;
    @include bp(rev-medium){
        padding: 50px 20px 30px 20px;
    }
    @include bp(medium){
        padding: 100px 10px 200px 10px;
    }
}

.padding-custom-7{
    padding: 100px 20px 150px 20px;
    @include bp(rev-medium){
        padding: 100px 20px 150px 20px;
    }
    @include bp(medium){
        padding: 100px 10px 200px 10px;
    }
}

.padding-custom-8{
    padding: 90px 10px 150px;
    @include bp(rev-medium){
        padding: 90px 20px 30px;
    }
}

.padding-custom-9{
    padding: 40px 20px 150px 20px;
    @include bp(medium){
        padding: 90px 10px 200px 10px;
    }
}

.padding-custom-10{
    padding: 40px 20px 100px 20px;
    @include bp(medium){
        padding: 90px 10px 150px 10px;
    }
}

.padding-custom-11{
    padding: 30px 20px 30px 20px;
    @include bp(medium){
        padding: 50px 10px 150px 10px;
    }
}

.padding-custom-12{
    padding: 40px 10px 130px 10px;
    @include bp(medium){
        padding: 50px 10px 150px 10px;
    }
}

.padding-custom-13{
    padding: 40px 10px 0 10px;
    @include bp(medium){
        padding: 100px 10px 40px 10px;
    }
}

.padding-custom-14{
    padding: 20px 10px 100px 10px;
    @include bp(medium){
        padding: 20px 10px 150px 10px;
    }
}

.padding-custom-15{
    padding: 30px 10px 90px 10px;
    @include bp(medium){
        padding: 150px 10px 150px 10px;
    }
}

.padding-custom-16{
    padding: 120px 10px 150px;
    @include bp(rev-medium){
        padding: 50px 10px 100px;
    }
}

.padding-custom-17{
    padding: 90px 10px 20px;
    @include bp(rev-medium){
        padding: 40px 10px 20px;
    }
}

.padding-custom-18{
    padding: 70px 20px 150px 20px;
    @include bp(xsmall){
        padding: 80px 20px 150px 20px;
    }
    @include bp(medium){
        padding: 90px 10px 200px 10px;
    }
}

.padding-custom-19{
    padding: 120px 10px 150px;
    @include bp(rev-medium){
        padding: 50px 10px 150px;
    }
}

.padding-custom-blog{
    padding: 55px 0 150px 0;
    @include bp(small){
        padding: 55px 0 200px 0;
    }
    @include bp(xxlarge){
        padding: 50px 0 200px 0;
    }
}

.padding-custom-faq{
    padding: 90px 10px 150px;
    @include bp(rev-small){
        padding: 90px 10px 150px;
    }
    @include bp(rev-xsmall){
        padding: 60px 10px 150px;
    }
}


.padding-sm-5{
    @include bp(rev-small){
        padding: 0 5px;
    }
}

.padding-md-5{
    @include bp(rev-medium){
        padding: 0 5px;
    }
}


// padding content
.padding-content-1{
    padding: 40px 5px 40px 5px;
    @include bp(small){
        padding: 50px 0 50px 0;
    }
    @include bp(medium){
        padding: 120px 15px 120px 0;
    }
    @include bp(xxlarge){
        padding: 120px 15px 120px 0;
    }
}

.padding-content-2{
    padding: 40px 10px 40px 10px;
    @include bp(small){
        padding: 50px 0 50px 0;
    }
    @include bp(medium){
        padding: 150px 15px 100px 0;
    }
    @include bp(xxlarge){
        padding: 170px 15px 150px 0;
    }
}

.padding-content-3{
    padding: 50px 5px 50px 5px;
    @include bp(small){
        padding: 50px 0 50px 0;
    }
    @include bp(medium){
        padding: 160px 15px 80px 0;
    }
    @include bp(xxlarge){
        padding: 160px 15px 80px 0;
    }
}


// .content-width-small{
//     margin: 0 auto;
//     max-width: 500px;
// }

// .content-width-normal{
//     margin: 0 auto;
//     max-width: 700px;
// }


.page-index{
    main{
        padding-top: 70px;
        @include bp(rev-medium){
            padding-top: 60px;
        }
    }
}

.page-detail{
    main{
        padding-top: 40px;
        @include bp(rev-medium){
            padding-top: 60px;
        }
    }
}

main{
    display: block;
    position: relative;
    padding-top: 70px;
    @include bp(rev-medium){
        padding-top: 60px;
    }
}

.container-md-full{
    @include bp(excl-medium){
        width: 100%!important;
    }
}

.container-full {
    margin: 0 -10px;
}

.container-small,
.container-normal,
.container-large{
    margin: 0 auto;
    padding: 0 15px;
}

.container-small{
    @include bp(small){
        width: 730px;
    }
    @include bp(medium){
        width: 950px;
    }
    @include bp(large){
        width: 1000px;
    }
    @include bp(xlarge){
        width: 1000px;
    }
    @include bp(rev-small){
        width: 100%;
    }
}

.container-normal{
    @include bp(small){
        width: 730px;
    }
    @include bp(medium){
        width: 950px;
    }
    @include bp(large){
        width: 1120px;
    }
    @include bp(xlarge){
        width: 1240px;
    }
    @include bp(rev-small){
        width: 100%;
    }
}

.container-large{
    @include bp(small){
        width: 730px;
    }
    @include bp(medium){
        width: 950px;
    }
    @include bp(large){
        width: 1170px;
    }
    @include bp(xlarge){
        width: 1360px;
    }
    @include bp(rev-small){
        width: 100%;
    }
}