.category-content{
	@include bp(medium){
		padding-left: 100px;
		// width: calc(100% - 240px);
		// flex: 0 0 calc(100% - 240px);
		// max-width: calc(100% - 240px);
	}
	@include bp(large){
		// width: calc(100% - 320px);
		// flex: 0 0 calc(100% - 320px);
		// max-width: calc(100% - 320px);
	}
}