.js-slider{
    display: none;
    &.slick-initialized{
        display: block;
    }
}

.js-slider{
    .js-slide-item{
        outline: none;
    }
}

.slider-center{
    .slick-track{
        display: flex;
        align-items: center;
    }
}

.slider-margin{
    @include bp(rev-xxlarge){
        margin: 0 30px;
    }
    @include bp(rev-xsmall){
        margin: 0 15px;
    }
}

.slider-margin-ext{
    @include bp(rev-xxlarge){
        margin: 0 30px;
    }
}

.slick-dots {
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0!important;
    bottom: -40px;
    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 8px;
        margin: 0 6px;
        background-color: #d4e2f1;
        cursor: pointer;
        padding: 0!important;
        vertical-align: middle;
        @include transition(all 250ms ease);
        &.slick-active,
        &:hover{
            background-color: #2a70b7;
        }
        button {
            &:before {
                content: none;
            }
        }
        button {
            display: none;
        }
    }
}

.slick-arrows {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 40%;
    margin-top: -25px;
    @include bp(rev-xsmall){
    }
    &.slick-vertical{
        @include bp(rev-medium){
            top: 50%;
        }
        @include bp(medium){
            top: auto;
            margin-top: 0;
            .icon{
                &.icon-chevron-up{
                    &:after{
                        content: '\e926';
                        color: $color-blue-normal;
                        font-size: inherit;
                        margin: 0;
                        position: relative;
                        top: auto;
                        left: auto;
                        width: auto;
                        height: auto;
                        // display: none;
                    }
                }
                &.icon-chevron-down{
                    &:after{
                        content: '\e925';
                        color: $color-blue-normal;
                        font-size: inherit;
                        margin: 0;
                        position: relative;
                        top: auto;
                        left: auto;
                        width: auto;
                        height: auto;
                        // display: none;
                    }
                }
            }
            &.slick-prev{
                top: 0;
                left: 50%;
                margin-left: -25px;
            }
            &.slick-next{
                bottom: 0;
                right: auto;
                left: 50%;
                margin-left: -25px;
            }
        }
    }
    &.slick-arrow-blue{
        &.slick-prev{
            @include bp(rev-xsmall){
                left: -30px;
            }
        }
        &.slick-next{
            @include bp(rev-xsmall){
                right: -30px;
            }
        }
        .icon{
            color: #ffffff;
            @include bp(rev-xsmall){
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
            &:after{
                @include bp(rev-xsmall){
                    margin-left: -20px;
                    margin-top: -20px;
                }
            }
            &:after{
                color: $color-blue-normal;
            }
            &:hover{
                color: #ffffff;
            }
        }
    }
    &.slick-gallery{
        top: auto;
        margin-top: 0;
        bottom: -20px;
        z-index: 4;
        @include bp(rev-small){
            bottom: -40px;
        }
        &.slick-prev{
            left: 46%;
            right: auto;
            transform: translateX(-50%);
            @include bp(rev-small){
                left: 46%;
            }
            @include bp(rev-xsmall){
                left: 45%;
            }
        }
        &.slick-next{
            left: 54%;
            right: auto;
            transform: translateX(-50%);
            @include bp(rev-small){
                left: 54%;
            }
            @include bp(rev-xsmall){
                left: 55%;
            }
        }
        .icon{
            @include font-size(18px);
            width: 40px;
            height: 40px;
            line-height: 40px;
            &:after{
                display: none;
            }
            &:hover{
                color: $color-orange-normal;
            }
        }
        &.slick-disabled{
            .icon{
                color: #d7e4f2;
                &:hover{
                    color: #d7e4f2;
                }
            }
        }
    }
    &.slick-white{
        bottom: -80px;
        .icon{
            color: #ffffff;
            &:hover{
                color: #ffffff;
            }
        }
    }
    .icon{
        display: block;
        width: 50px;
        height: 50px;
        @include font-size(22px);
        text-align: center;
        line-height: 50px;
        color: $color-blue-normal;
        &:after{
            content: '\e93a';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 100%;
            height: 100%;
            margin-left: -25px;
            margin-top: -25px;
            @include font-size(50px);
            color: #ffffff;
            font-family: $project-name;
            z-index: -1;
        }
        &:hover{
            color: $color-orange-normal;
        }
    }
    &.slick-disabled{
        opacity: 0.4;
    }
    &.slick-prev {
        left: -80px;
        @include bp(rev-xxlarge){
            left: -50px;
        }
        .icon{
            &:after{
                transform: rotateY(180deg);
            }
        }
    }
    &.slick-next {
        right: -80px;
        @include bp(rev-xxlarge){
            right: -50px;
        }
    }
}
