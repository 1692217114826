.basket-content{
	@include bp(medium){
		padding-right: 100px;
	}
}

.basket-page-group{
	&:before{
		@include bp(medium){
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			height: 170px;
			background-color: $color-orange-normal;
			z-index: -1;
			width: 100%;
		}
	}
}

.basket-page-group-thank{
	&:before{
		@include bp(medium){
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			height: 340px;
			background-color: $color-orange-normal;
			z-index: -1;
			width: 100%;
		}
	}
}

.basket-action{
	.delta{
		@include bp(rev-large){
			@include font-size(22px);
		}
	}
	@include bp(rev-small){
		.delta{
			@include font-size(20px);
		}
	}
	@include bp(rev-xsmall){
		.delta{
			@include font-size(18px);
		}
		.btn-min-width-normal{
			min-width: auto;
		}
	}
	@include bp(rev-xssmall){
	}
}

.product-result-group{
	max-height: 330px;
    overflow: auto;
}

.product-result-item{
	margin-bottom: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid #dddddd;
}

.basket-summary-item{
	margin-bottom: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid #dddddd;
	&:last-child{
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: 0;
	}
}

.product-result-content{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.delta{
		max-height: 65px;
		margin-bottom: 0;
	}
	&:hover{
		.delta{
			color: $color-orange-normal;
		}
		img{
			opacity: 0.5;
		}
	}
}

.product-result-image-group{
	float: left;
	width: 90px;
	height: 90px;
	background-color: $color-orange-normal;
}

.product-result-text-group{
	display: block;
	height: 100%;
	overflow: hidden;
	padding-left: 12px;
	width: calc(100% - 90px);
	padding-right: 40px;
}

.product-result-info-group{
	width: 100%;
	margin-top: 20px;
}

.product-result-info-list{

}

.product-result-info-list-item{
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
	margin-bottom: 10px;
	&:last-child{
		margin-right: 0;
	}
}

.product-result-info-list-icon{
	position: relative;
	top: 2px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	color: rgba(0, 0, 0, 0.3);
	@include font-size($fs-14);
}

.product-result-info-list-text{
	display: inline-block;
	vertical-align: middle;
	color: $color-black-normal;
	font-weight: 400;
	line-height: 1.2;
	@include font-size($fs-14);
	@include bp(xsmall){
		@include font-size($fs-16);
	}
	@include bp(small){
		@include font-size($fs-18);
	}
}