/* Table block */

.table-responsives{
	@include bp(rev-small){
		-ms-overflow-style: -ms-autohiding-scrollbar;
		min-height: .01%;
		border: 0!important;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
	}
}
table{
	border-collapse: collapse;
	width: 100%;
	@include bp(rev-small){
		width: 768px;
	}
	th{
		padding: 12px 10px 12px 10px;
		color: $color-black-normal;
		@include font-size($fs-20);
		font-weight: 900;
		text-align: center;
		line-height: 1.2;
		@include bp(excl-large){
			@include font-size($fs-16);
		}
		@include bp(rev-large){
			@include font-size($fs-16);
		}
		&:first-child{
			text-align: left;
		}
	}
	tbody{
		tr{
			border-bottom: 1px solid #f0efef;
			&:hover{
				background-color: #f9f9f9;
				.table-text{
					font-weight: 700;
				}
			}
		}
	}
	.is-sold{
		.table-text{
			color: #d1d1d1;
		}
		&:hover{
			background-color: transparent;
		}
	}
	td{
		color: $color-black-normal;
		font-weight: 400;
		line-height: 1.4;
		text-align: center;
		height: 50px;
		@include font-size($fs-18);
		@include bp(excl-large){
			@include font-size($fs-16);
		}
		@include bp(rev-large){
			@include font-size($fs-16);
		}
		&:first-child{
			text-align: left;
			.table-text{
				justify-content: flex-start;
			}
		}
	}
	.table-text{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px 10px 2px 10px;
	}
	.table-text-bold{
		font-weight: 700;
	}
	.table-btn{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 10px 12px 10px;
		font-weight: 900;
		text-transform: uppercase;
		@include font-size($fs-14);
		&:hover{
			background-color: $color-yellow-normal;
		}
	}
}

