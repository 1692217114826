.newsletter-section{
	padding-top: 50px;
	padding-bottom: 150px;
	// overflow: hidden;
	// @include bp(rev-xsmall){
		// padding-top: 130px;
	// }
}

.newsletter-content{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
}

.newsletter-form{
	.btn-icon{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.newsletter-form-input{
	
}

.newsletter-form-submit{
	
}

.newsletter-shape{
	position: absolute;
}

.newsletter-shape-1{
	top: 50px;
	left: -30px;
	transform: rotateZ(-150deg);
	@include font-size(140px);
	@include bp(rev-xlarge){
		left: -30px;
	}
}

.newsletter-shape-2{
	top: 60px;
	left: 35px;
	transform: rotateZ(-110deg);
	@include font-size(70px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
}

.newsletter-shape-3{
	top: -45px;
	left: 340px;
	transform: rotateZ(60deg);
	@include font-size(100px);
	@include bp(rev-xlarge){
		left: 100px;
	}
	@include bp(rev-small){
		display: none;
	}
}

.newsletter-shape-4{
    top: 80px;
    left: 620px;
	transform: rotateZ(80deg);
	@include font-size(30px);
}

.newsletter-shape-5{
    bottom: 0;
    left: 970px;
	transform: rotateZ(210deg);
	@include font-size(100px);
}

.newsletter-shape-6{
	top: 15px;
	right: 730px;
	transform: rotateZ(220deg);
	@include font-size(40px);
	z-index: 2;
	@include bp(rev-medium){
		right: 500px;
		// right: 350px;
	}
	@include bp(rev-xsmall){
		right: 160px;
		top: 10px;
	}
}

.newsletter-shape-7{
	top: -60px;
	right: 680px;
	transform: rotateZ(380deg);
	@include font-size(130px);
	@include bp(rev-medium){
		right: 460px;
		// right: 300px;
		// top: 20px;
	}
	@include bp(rev-small){
		display: none;
	}
}

.newsletter-shape-8{
	top: 10px;
	right: 300px;
	transform: rotateZ(180deg);
	@include font-size(30px);
	z-index: 2;
	@include bp(rev-small){
		display: none;
	}
}

.newsletter-shape-9{
	bottom: 20px;
	right: 100px;
	transform: rotateZ(150deg);
	@include font-size(80px);
	z-index: 2;
	@include bp(rev-small){
		right: 30px;
	}
}

.newsletter-shape-10{
	top: -10px;
	right: -40px;
	transform: rotateZ(150deg);
	@include font-size(100px);
	@include bp(rev-small){
		top: 30px;
	}
}
