/* HR helper */

.hr{
	border: 0;
	margin-bottom: 15px;
	margin-top: 15px;
}

.hr-line {
    margin: 30px 0;
    height: 1px;
    background: $color-black-normal;
}