/* Pagination block */

.pagination-list{
    display: block;
    width: 100%;
    text-align: center;
    margin: 0;
    @include bp(rev-small){
        margin-top: 30px;
    }
    @include bp(xsmall){
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto!important;
    }
}

.pagination-list-item{
    display: inline-block;
    vertical-align: middle; 
    &:hover,
    &.is-active{
        .pagination-list-link{
            color: #ffffff;
            &:after{
                opacity: 1; 
            }
        }
    }
}

.pagination-list-link{
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $color-black-normal;
    font-weight: bold;
    text-transform: uppercase;
    @include font-size($fs-16);
    &:after{
        content: '\e93a';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 100%;
        height: 100%;
        margin-left: -20px;
        margin-top: -18px;
        @include font-size(40px);
        opacity: 0;
        color: $color-blue-normal;
        font-family: $project-name;
        z-index: -1;
        // background-size: cover;
        // background-position: center;
        // background-repeat: no-repeat;
        // background-image: url( '../images/rectangle-blue.svg' );
    }
}
