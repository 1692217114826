/* Hidden helpers */

.invisible {
    visibility: hidden;
}

.visible{
    visibility: visible;
}

.opacity-0 {
	opacity: 0;
}

.opacity-1 {
	opacity: 1;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.overflow {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-hidden-md{
	@include bp(rev-medium){
		overflow: hidden;
	}
}