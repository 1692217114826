.news-group{
	@include bp(rev-medium){
		margin-top: 5em;
		padding-left: 20px;
		padding-right: 30px;
		padding-bottom: 50px;
		padding-top: 30px;
	}
	@include bp(rev-small){
		background-color: $color-yellow-normal;
		margin: 5em -10px 0;
		width: auto;
	}
	@include bp(rev-xsmall){
		padding-top: 50px;
	}
	.beta{
		padding-left: 75px;
		@include bp(rev-xsmall){
			padding-left: 55px;
		}
	}
	@include bp(medium){
		position: absolute;
		top: -110px;
		right: 0;
		padding-top: 70px;
		padding-bottom: 40px;
		.beta{
			line-height: 1.2;
		}
	}
	@include bp(excl-large){
		padding-left: 30px;
		padding-right: 30px;
	}
	@include bp(excl-xlarge){
		.beta{
			@include font-size(38px);
		}
	}
	@include bp(excl-large){
		.beta{
			@include font-size(32px);
		}
	}
}

.news-item{
	width: 100%
}

.news-date{
	position: relative;
    top: -4px;
	float: left;
	width: 60px;
	margin-right: 15px;
	color: #040707;
	font-weight: 400;
	line-height: 1.2;
	@include font-size(28px);
	@include bp(rev-small){
		@include font-size(24px);
	}
	@include bp(rev-xsmall){
		width: 50px;
		margin-right: 5px;
		@include font-size(22px);
	}
}

.news-text-block{
	display: block;
	height: 100%;
	overflow: hidden;
	p{
		margin-bottom: 1em;
		line-height: 22px;
	}
	strong {
		display: block;
	}
}


.news-content{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
}

.news-shape{
	position: absolute;
}

.news-shape-1{
	top: 50px;
	left: -20px;
	transform: rotateZ(-20deg);
	@include font-size(60px);
	@include bp(rev-xlarge){
		left: -30px;
	}
}

.news-shape-2{
	top: 70px;
	left: 20px;
	transform: rotateZ(-160deg);
	@include font-size(30px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
}

.news-shape-3{
	bottom: 50px;
	left: -5px;
	transform: rotateZ(90deg);
	@include font-size(50px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-small){
		bottom: 25%;
	}
}

.news-shape-4{
	bottom: 50px;
	left: 60px;
	transform: rotateZ(60deg);
	@include font-size(90px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
}

.news-shape-5{
	bottom: 10px;
	left: 460px;
	transform: rotateZ(-40deg);
	@include font-size(20px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-small){
		left: 200px;
	}
}

.news-shape-6{
	bottom: 30px;
	left: 650px;
	transform: rotateZ(140deg);
	@include font-size(60px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
}


.news-shape-7{
	top: -10px;
	left: 800px;
	transform: rotateZ(-140deg);
	@include font-size(90px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-medium){
		right: 25px;
		left: auto;
	}
}

.news-shape-8{
	top: 10px;
	left: 900px;
	transform: rotateZ(0);
	@include font-size(60px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-medium){
		top: 45%;
		left: auto;
		right: -10px;
	}
}

.news-shape-9{
	bottom: 40px;
	right: 860px;
	transform: rotateZ(30deg);
	z-index: 2;
	@include font-size(40px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-medium){
		bottom: 50%;
		left: 40px;
	}
}

.news-shape-10{
	bottom: -10px;
	right: 790px;
	transform: rotateZ(60deg);
	@include font-size(120px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-medium){
		bottom: 45%;
		left: 25px;
	}
}

.news-shape-11{
	top: -5px;
	right: 210px;
	transform: rotateZ(-15deg);
	@include font-size(100px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-medium){
		top: 20%;
	}
	@include bp(rev-small){
		right: -25px;
	}
}

.news-shape-12{
	top: 60px;
	right: 190px;
	transform: rotateZ(15deg);
	@include font-size(50px);
	@include bp(rev-xlarge){
		// left: 60px;
	}
	@include bp(rev-medium){
		top: 28%;
	}
	@include bp(rev-small){
		top: 27%;
		right: 15px;
	}
}

.news-shape-13{
	bottom: 60px;
	right: 80px;
	transform: rotateZ(140deg);
	@include font-size(100px);
	@include bp(rev-xxlarge){
		right: -20px;
		// left: 60px;
	}
}

.news-shape-14{
	bottom: 30px;
	right: 30px;
	transform: rotateZ(45deg);
	@include font-size(50px);
	@include bp(rev-xxlarge){
		right: -10px;
		// left: 60px;
	}
}



