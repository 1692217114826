/* Image helpers */

.img {
    display: block;
}

.img-center{
    margin: 0 auto;
}

.img-full{
    width: 100%;
}

.img-responsive{
	display: block;
	max-width: 100%;
	height: auto;
}

.img-cover{
	height: 100%;
	object-fit: cover;
}

.bg-image{
	display: block;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.bg-image-absolute{
	position: absolute;
	top: 0;
	left: 0;
}

.bg-image-people{
	@include bp(small){
		background-attachment: fixed;
	}
}

.bg-image-footer{
	position: absolute;
	top: -130px;
	left: 0;
	background-position: top;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
	@include bp(rev-xsmall){
		top: -90px;
	}
}

.bg-image-header{
	position: absolute;
	top: 72px;
	height: 80px;
	display: block;
	width: 100%;
	left: 0;
	background-position: top;
	z-index: 0;
	pointer-events: none;
	@include bp(rev-xlarge){
		background-size: auto;
	}
	@include bp(rev-medium){
	 	display: none;
	}
}

.bg-image-header-dropdown-bottom{
	position: absolute;
	bottom: -89px;
	height: 90px;
	display: block;
	width: 100%;
	left: 0;
	background-position: top;
	z-index: 0;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}


.bg-image-green-right,
.bg-image-orange-right{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 80px;
	background-position: left;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-gray-right{
	position: absolute;
	top: 0;
	right: -2px;
	height: 100%;
	width: 80px;
	background-position: right;
	z-index: 1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-gray-top{
	position: absolute;
	top: -89px;
	left: 0;
	height: 90px;
	background-position: top;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-white-top{
	position: absolute;
	top: -79px;
	left: 0;
	height: 80px;
	background-position: top;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-orange-top{
	position: absolute;
	top: -104px;
	left: 0;
	height: 105px;
	background-position: bottom;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-orange-top-normal{
	position: absolute;
	top: -59px;
	left: 0;
	height: 60px;
	background-position: top;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-green-bottom-normal,
.bg-image-orange-bottom-normal{
	position: absolute;
	bottom: -59px;
	left: 0;
	height: 60px;
	background-position: bottom;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-orange-bottom-ext{
	position: absolute;
	top: 160px;
	left: 0;
	height: 60px;
	background-position: bottom;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
	@include bp(rev-medium){
		top: 110px;
	}
}

.bg-image-orange-top-ext{
	position: absolute;
	bottom: 340px;
	left: 0;
	height: 60px;
	background-position: bottom;
	z-index: -1;
	@include bp(rev-xlarge){
		background-size: auto;
	}
}

.bg-image-triangle{
	position: absolute;
	top: 0;
	left: -1px;
	width: 60px;
	z-index: 4;
	height: 100%;
	background-position: top;
	@include bp(rev-medium){
		display: none;
	}
}

.bg-image-triangle-mobile{
	@include bp(rev-medium){
		display: block;
		width: 100%;
		height: 60px;
		left: 0;
		background-image: url('../images/triangle-mobile.svg')!important;
	}
}

.bg-image-triangle-right{
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	z-index: 4;
	height: 100%;
	background-position: top;
	transform: rotateY(180deg);
	@include bp(rev-medium){
		display: none;
	}
}

.shape-circle{
	position: absolute;
	top: -40px;
	left: 100px;
	z-index: 1;
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 100%; 
	background-color: $color-blue-dark;
	@include bp(rev-small){
		left: 30px;
	}
}

.shape-hero-1{
	position: absolute;
	top: 30%;
	left: -80px;
	z-index: -1;
	transform: translateY(-50%) rotateZ(20deg);
	@include font-size(200px);
	@include bp(rev-small){
		left: -140px;
	}
	@include bp(rev-xsmall){
		left: -170px;
	}
}

.shape-hero-2{
	top: auto;
	width: 50px;
	height: 50px;
	bottom: 80px;
	left: 30px;
}

.shape-hero-3{
	position: absolute;
	top: 15%;
	left: 40px;
	z-index: -1;
	transform: translateY(-50%) rotateZ(-20deg);
	@include font-size(90px);
	@include bp(rev-small){
		top: 20%;
		left: -20px;
	}
	@include bp(rev-xsmall){
		left: -40px;
	}
}

.shape-hero-4{
	position: absolute;
	top: 40%;
	right: 20px;
	z-index: -1;
	transform: rotateZ(40deg);
	@include font-size(70px);
	@include bp(rev-small){
		right: 0;
		top: 10%;
	}
}

.shape-hero-5{
	position: absolute;
	top: 10%;
	right: -110px;
	// z-index: -1;
	z-index: 6;
	transform: rotateZ(140deg);
	@include font-size(250px);
	@include bp(rev-small){
		top: -30%;
		right: -170px;
	}
	&:before{
		color: $color-green-normal;
	}
}

.shape-hero-6{
	position: absolute;
	top: -120px;
	left: 22%;
	// z-index: -1;
	z-index: 6;
	@include font-size(240px);
	@include bp(rev-medium){
		display: none;
	}
}

.shape-hero-7{
	position: absolute;
	top: 40%;
	left: -40px;
	z-index: 5;
	transform: rotateZ(-110deg);
	@include font-size(270px);
	@include bp(rev-small){
		left: -180px;
	}
	&:before{
		color: $color-green-normal;
	}
}


.shape-news-1{
	position: absolute;
	display: block;
	top: -60px;
	left: 130px;
	@include font-size(70px);
	transform: rotateZ(40deg);
	@include bp(rev-small){
		left: 20px;
	}
}



.shape-side-circle{
	position: absolute;
	top: 15%;
	left: -180px;
	// z-index: 5;
	// transform: translateY(-50%);
	@include font-size(280px);
	@include bp(rev-small){
		left: -110px;
		@include font-size(160px);
	}
}

.shape-side-circle-top{
	top: 10%;
	transform: translateY(0);
}

.shape-side-circle-bottom{
	bottom: 200px;
}

.shape-side-circle-right{
	left: auto;
	top: 30%;
	right: -140px;
}

.shape-side-circle-ext{
	left: -210px;
	@include bp(excl-xlarge){
		left: -230px;
	}
	@include bp(excl-large){
		left: -230px;
	}
	@include bp(rev-medium){
		left: auto;
		right: -220px;
	}
	@include bp(rev-small){
		right: -120px;
	}
}

.shape-side-triangle{
	position: absolute;
	top: 50%;
	left: -180px;
	z-index: -1;
	transform: translateY(-50%) rotateZ(20deg);
	@include font-size(280px);
	@include bp(rev-small){
		left: -70px;
		@include font-size(160px);
	}
}

.shape-side-triangle-bottom{
	top: auto;
	top: 80%;
	left: -130px;
	transform: translateY(0) rotateZ(20deg);
	@include bp(rev-small){
		left: -90px;
	}
}

.shape-side-triangle-top{
	top: 10%;
	left: -130px;
	transform: translateY(0) rotateZ(10deg);
	@include bp(rev-small){
		left: -90px;
	}
}

.shape-side-triangle-left{
	top: 60%;
	left: -130px;
	transform: translateY(0) rotateZ(10deg);
	@include bp(rev-small){
		left: -90px;
	}
}

.shape-side-triangle-blue:before{
	color: $color-blue-dark;
}


.shape-side-square{
	position: absolute;
	top: 50%;
	right: -200px;
	z-index: -1;
	transform: translateY(-50%) rotateZ(70deg);
	@include font-size(280px);
	@include bp(rev-small){
		right: -110px;
		@include font-size(160px);
	}
}

// Pattern


.main-blog-pattern{
	position: absolute;
	top: -100px;
	left: 0;
	display: block;
	width: 729px;
	height: 637px;
	background-repeat: no-repeat;
	background-position: bottom left;
	background-image: url('../images/rectangle-skew-5-new.svg');
	@include bp(rev-medium){
		top: -15px;
		width: 100%;
		height: 100%;
	}
	@include bp(rev-small){
		display: none;
	}
	@include bp(rev-xsmall){
		background-position: bottom left -150px;
	}
	@include bp(medium){
		left: -405px;
	}
	@include bp(large){
		left: -320px;
	}
	@include bp(xlarge){
		left: -260px;
	}
}

.main-news-pattern{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top right;
	background-image: url('../images/rectangle-skew-4.svg');
	@include bp(rev-medium){
		top: -30px;
		width: 100%;
		height: 100%;
	}
	@include bp(rev-small){
		display: none;
	}
	@include bp(medium){
	}
	@include bp(large){
	}
	@include bp(xlarge){
	}
}

.icon[class*="icon-shape"]{
	pointer-events: none;
}