.year-group{
	@include bp(rev-medium){
		margin-bottom: 40px;
		width: calc(100% - 80px);
		-ms-flex: 0 0 calc(100% - 80px);
		flex: 0 0 calc(100% - 80px);
		max-width: calc(100% - 80px);
		margin: 0 auto 40px;
	}
	@include bp(medium){
		width: 130px;
		-ms-flex: 0 0 130px;
		flex: 0 0 130px;
		max-width: 130px;
		padding: 50px 0;
		height: 425px;
	}
	.slick-current{
		.year-text{
			font-weight: 700;
			&:after{
				opacity: 1;
			}
		}
	}
}

.year-item{
	padding: 12px 0;
	text-align: center;
	cursor: pointer;
}

.year-text{
	position: relative;
	color: $color-black-normal;
	@include font-size(26px);
	font-weight: 400;
	line-height: 1;
	@include bp(rev-xsmall){
		@include font-size(22px);
	}
	&:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 124px;
        height: 55px;
        margin-left: -62px;
        margin-top: -27px;
        opacity: 0;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( '../images/rectangle-skew-1.svg' );
        @include bp(rev-xsmall){
        	width: 80px;
        	height: 30px;
        	margin-left: -40px;
        	margin-top: -13px;
        }
    }
}

.history-group{
	@include bp(medium){
		width: calc(100% - 130px);
		-ms-flex: 0 0 calc(100% - 130px);
		flex: 0 0 calc(100% - 130px);
		max-width: calc(100% - 130px);
	}
}

.history-group-ext{
	@include bp(medium){
		padding-left: 30px;
	}
}
