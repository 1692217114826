/* Animate helpers */
@-webkit-keyframes rotate-forever {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg); 
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg); 
    } 
}

@keyframes rotate-forever {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg); 
    }
}

.loading-spinner {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: rotate-forever;
    animation-name: rotate-forever;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    height: 40px;
    width: 40px;
    border: 3px solid $color-yellow-normal;
    border-right-color: transparent;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: inline-block; 
}


@keyframes swing {
    20% {
        transform: rotate(10deg)
    }

    40% {
        transform: rotate(-6deg)
    }

    60% {
        transform: rotate(3deg)
    }

    80% {
        transform: rotate(-3deg)
    }

    to {
        transform: rotate(0deg)
    }
}

.swing {
    transform-origin: top center;
    animation-name: swing;
    animation-duration: 1s;
    animation-fill-mode: both;
}


@-webkit-keyframes slideOutLeft {
    0% {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    100% {
        visibility: hidden;
        -webkit-transform: translate3d(-70%,0,0);
        transform: translate3d(-70%,0,0)
    }
}

@keyframes slideOutLeft {
    0% {
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0)
    }

    100% {
        visibility: hidden;
        -webkit-transform: translate3d(-70%,0,0);
        transform: translate3d(-70%,0,0)
    }
}

@keyframes pulse {
    0% {
        transform: scaleX(1) rotateZ(0deg);
    }

    25% {
        transform: scale3d(1.10,1.10,1.10) rotateZ(-5deg);
    }

    75% {
        transform: scale3d(1.10,1.10,1.10) rotateZ(5deg);
    }

    to {
        transform: scaleX(1) rotateZ(0deg);
    }
}

.pulse {
    animation: pulse 1.5s infinite;
}

.js-paralax-effect{
    transition: margin-top 300ms ease-out;
}